import React, { createContext, useState, useEffect } from "react"
import moment from "moment"
import {
    GetAll,
    fetchAll,
    postfunc,
    putfunc,
} from "../services/mainService"
import useSwr,{mutate,trigger,useSWRConfig} from "swr"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"
import { useLocation } from "react-router-dom"
import Auhtentication from ".././authMidlleware/userMiddleWare"
export const teacherContext = createContext()
teacherContext.displayName = "teacherContext"

const TeacherContextProvider = props => {
    const [teacherObj, setteacherObj] = useState({
      teacherName: "",
      teacherPhone: "",
      teacherAddress: "", 
      teacherGender:"", 
      teacherAddress:"", 
      educationalLevel:"", 
      teacherStatus:"", 
      username:"",
      password:""
      })
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const [showpassword, setshowpassword] = useState(false)
  const [showicon, setshowicon] = useState("mdi mdi-eye")
  const location = useLocation();
  Auhtentication(location.pathname)

  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token
    },
  };
  let epiEndPoint=process.env.REACT_APP_ENDPOINT
  
  async function fetchAll(path) {
    const {data}=await axios.get(epiEndPoint+"/"+path,headers);
    return data
  };
  const handleshow=()=>{
    if(showpassword){
      setshowicon("mdi mdi-eye")
    }else{
      setshowicon("mdi mdi-eye-off")
    }
    setshowpassword(!showpassword)
   }
//   useSwr("branch",getMyprint);
let { data:teacherlist } = useSwr("teacher",fetchAll)
let teacherData=[]

let chechArray=Array.isArray(teacherlist)
if(chechArray==true){
  teacherData=teacherlist
}
  const notify = (message, type) => toast[type](message);
  const tog_standard = () => {
    setIsOpen(!isOpen)
    setteacherObj({
      teacherName: "",
      teacherPhone: "",
      teacherAddress: "", 
      teacherGender:"", 
      teacherAddress:"", 
      educationalLevel:"", 
      teacherStatus:"",
      username:"",
      password:"" 
      })
    
    setEditCondition(false)
  }

  const HandleSubmitBtn = async e => {
    try {
      if(teacherObj.password=="") return notify("password is required", "error")
      setdisabled(true)
      setload(true)
      if (editCondition) {
       
        const {data} = await putfunc("teacher",teacherObj,headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("teacher", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setteacherObj({
            teacherName: "",
            teacherPhone: "",
            teacherAddress: "", 
            teacherGender:"", 
            teacherAddress:"", 
            educationalLevel:"", 
            teacherStatus:"", 
            username:"",
            password:""
          })
        }else{
          notify(message, "error");
          setdisabled(false)
          setload(false)
        }
      } else {
        // notify("welcome", "error");
        if(teacherObj.teacherStatus==""){
          delete teacherObj.teacherStatus
        }
// return console.log("teacherObj",teacherObj)
        const {data} = await postfunc("teacher",teacherObj,headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("teacher", fetchAll)
          setteacherObj({
            teacherName: "",
            teacherPhone: "",
            teacherAddress: "", 
            teacherGender:"", 
            teacherAddress:"", 
            educationalLevel:"", 
            teacherStatus:"", 
            username:"",
            password:"" 
          })
          notify(message, "success")
          setIsOpen(false)
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
      setdisabled(false)
          setload(false)
    }
  }

  const handleChange = e => {
    setteacherObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const handleEdit = async (transData) => {
    let data = { ...transData };
    data.courseID = data.courseID?._id;
    data.teacherID = data.teacherID?._id;
    
    data.startedDate = moment(data.startedDate).format("YYYY-MM-DD");
    data.endDate = moment(data.endDate).format("YYYY-MM-DD");
    data.date = new Date(data.date);
    setEditCondition(true);
    setteacherObj(data);
    setIsOpen(true);
  };

  return (
    <teacherContext.Provider
      value={{
        handleEdit,
        handleChange,
        HandleSubmitBtn,
        tog_standard,
        isOpen,
        setIsOpen,
        teacherData,
        editCondition,
        teacherObj,
        disabled,
        load,
        handleshow,
        showpassword,
        showicon,
      }}
    >
      {props.children}
    </teacherContext.Provider>
  );


}

export default TeacherContextProvider
