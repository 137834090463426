import { createContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import "../pages/Dashboard/datatables.scss"
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import useSwr, { mutate } from "swr"

import jwtDecode from "jwt-decode"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Auhtentication from ".././authMidlleware/userMiddleWare"
import { GetAll, deletefunc, postfunc, putfunc } from "../services/mainService"

export const attendenceContext = createContext()
attendenceContext.displayName = "attendenceContext"
const AttendenceContextProvider = props => {
  const [isFits, setisFits] = useState(false)
  const [userImage, setuserImage] = useState()
  const [attendenceObj, setattendenceObj] = useState({
    studentID: "",
    courseID: "",
    classID: "",
    attended: "",
    permissions: [],
  })

  const [editCondition, setEditCondition] = useState(false)
  const [collapse, setcollapse] = useState(false)
  const [Icon, setIcon] = useState({
    iconPlusOrminus: "btn btn-info float-right feather icon-plus",
  })
  const [isOpen, setIsOpen] = useState(false)
  const [classData, setclassData] = useState([])
  const [tempClassData, setTemPClassData] = useState([])
  const [enrollmentData, setenrollmentData] = useState([])
  const [TempenrollmentData, setTempEnrollmentData] = useState([])
  const [teachersData, setteachersData] = useState([])
  const [currentUser, setcurrentUser] = useState({})
  const [loadEnrollTempData, setloadEnrollTempData] = useState([])
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const [revilidateAttendence, setrevilidateAttendence] = useState(1)
  const [sweetAlertConfirm, setsweetAlertConfirm] = useState(false)
  const [editattenceObj, seteditattenceObj] = useState({
    studentName: "",
    className: "",
    attended: "",
    attendenceStatus: "",
    reason: "",
  })

  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT

  async function fetchAll(path) {
    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  useEffect(() => {
    const getclass = async () => {
      try {
        const token = localStorage.getItem("token")
        let user = {}
        if (token) {
          let user = jwtDecode(token)
          setcurrentUser(user)
          user = user
        }
        const { data: teachers } = await GetAll("teacher", headers)
        const { data: EnrollmentStudent } = await GetAll(
          "Enrollment/activeStudents",
          headers
        )
        const { data: classesData } = await GetAll("class", headers)

        let checkteacher = Array.isArray(teachers)
        let checkEnrollmentStudent = Array.isArray(EnrollmentStudent)
        let checkclass = Array.isArray(classesData)

        if (checkteacher) {
          if (user.userType == "teacher") {
            let currentTeacher = teachers?.filter(
              teacher => teacher._id == user.teacherID
            )
            setteachersData(currentTeacher)
          } else {
            setteachersData(teachers)
          }
        }
        if (checkclass) {
          // setclassData(classesData)
          setTemPClassData(classesData)
        }
        if (checkEnrollmentStudent) {
          // setenrollmentData(EnrollmentStudent)
          setTempEnrollmentData(EnrollmentStudent)
          setloadEnrollTempData(EnrollmentStudent)
        }
        // if (attendenceData) {
        //   setattendenceList(attendenceData)
        // }
      } catch (error) {
        console.log(error.message)
      }
    }
    getclass()
  }, [revilidateAttendence])
  let attendenceList = []
  let { data: attendenceListData } = useSwr("attendence", fetchAll)

  let chechArray = Array.isArray(attendenceListData)

  if (chechArray == true) {
    attendenceList = attendenceListData
  }
  const tog_standard = () => {
    setIsOpen(!isOpen)
  }
  const initialValues = {
    teacherID: "",
    classID: "",
    day: "",
    items: [
      {
        studentID: "",
        // courseID: "",
        // classID: "",
        attended: false,
      },
    ],
  }
  const HandleSubmitBtn = async e => {
    try {
      //   e.preventDefault();

      if (editCondition) {
        // return console.log("attendenceObj",attendenceObj)
        const { data } = await putfunc("attendence", attendenceObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          mutate("users", fetchData)
          toast.success(message, {
            theme: "colored",
          })
          setIsOpen(false)
          setcollapse(false)
          setattendenceObj({
            name: "",
            phone: "",
            username: "",
            password: "",
            userType: "",
            permissions: [],
          })
        }
      } else {
        //  return console.log("attendenceObj",attendenceObj)

        const { data } = await postfunc("attendence", attendenceObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          toast.success(message, {
            theme: "colored",
          })
          mutate("users", fetchData)
          setattendenceObj({
            name: "",
            phone: "",
            username: "",
            password: "",
            userType: "",
            permissions: [],
          })
          // notify(message, "success");
          setIsOpen(false)
          setcollapse(false)
        } else {
          toast.warning(message, {
            theme: "colored",
          })
          // notify(message, "warning");
        }
      }
    } catch (error) {
      // console.log(error.response)
      // notify(error.message, "error");
      toast.error(error.message, {
        theme: "colored",
      })
    }
  }
  const location = useLocation()
  Auhtentication(location.pathname)

  const handleSubmitFormik = async (values, { resetForm }) => {
    try {
      setdisabled(true)
      setload(true)
      let classStudent = loadEnrollTempData.filter(
        std => std.classID._id == values.classID
      )
      // return console.log("classStudent",classStudent)

      let allStudentWithAttendence = classStudent.map((e, i) => {
        let temp = values.items.find(
          element => element?.studentID == e.studentID?._id
        )

        if (temp) {
          e.attended = 1
        } else {
          e.attended = 0
        }
        e.teacherID = values.teacherID
        e.day = values.day
        return {
          teacherID: e.teacherID,
          courseID: e.courseID._id,
          classID: e.classID?._id,
          userID: currentUser._id,
          studentID: e.studentID?._id,

          attended: e.attended,
          day: e.day,
          attendenceStatus: e.attended == 1 ? "present" : "upsent",
        }
      })

      const { data } = await postfunc(
        "attendence",
        allStudentWithAttendence,
        headers
      )

      const { status, message, info } = { ...data }
      if (status) {
        setdisabled(false)
        setload(false)
        toast.success(message)
        setenrollmentData([])
        setcollapse(false)
        resetForm()
        // notify(message, "success");
      } else {
        setdisabled(false)
        setload(false)
        toast.warning(message)
      }
    } catch (error) {
      toast.error(message)
      setdisabled(false)
      setload(false)
    }
  }
  const handleChange = e => {
    // console.log(e.target.name + " ", e.target.value);
    setattendenceObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const handleChangeEdit = e => {
    // console.log(e.target.name + " ", e.target.value);
    seteditattenceObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const handleEdit = async editData => {
    let data = { ...editData }
    setEditCondition(true)
    data.studentName = data.studentID?.studentName
    data.className = data.classID?.className
    // setattendenceObj(data)
    seteditattenceObj(data)
    // console.log("data", data)
    setIsOpen(!isOpen)
  }
  const handleSubmitEdit = async () => {
    try {
      console.log("editattenceObj", editattenceObj)
      setload(true)
      const { data } = await putfunc("attendence", editattenceObj, headers)
      const { status, message } = { ...data }
      if (status) {
        // mutate("attendence", fetchData)
        toast.success(message, {
          theme: "colored",
        })
        setrevilidateAttendence(2)
      } else {
        toast.error(message, {
          theme: "colored",
        })
      }
      setload(false)

      setIsOpen(false)
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      })
    }
    setload(false)
  }
  const handleDelete = data => {
    setattendenceObj(data)
    setsweetAlertConfirm(true)
  }
  const handleCheckBoxChange = e => {
    console.log(e.target.value)
    // if(e.target.checked==true ){
    //     console.log("checked")
    // }else{
    //     console.log("removed")
    // }
    if (e.target.checked) {
      setattendenceObj(prev => {
        let new_permission = [...prev.permissions, true]
        return { ...prev, [e.target.name]: true, permissions: new_permission }
      })
    } else {
      setattendenceObj(prev => {
        console.log("prev.permissions", prev.permissions)
        let new_permission = prev.permissions.filter(p => p !== true)
        console.log(new_permission)
        return { ...prev, [e.target.name]: false, permissions: new_permission }
      })
    }
  }
  const collapseToggle = () => {
    setcollapse(!collapse)
    if (collapse) {
      setIcon({
        iconPlusOrminus: "btn btn-info float-right feather icon-plus",
      })
    } else {
      setIcon({
        iconPlusOrminus: "btn btn-info float-right feather icon-minus",
      })
    }
  }
  const btnCancelTogle = () => {
    setcollapse(!collapse)
    setIcon({ iconPlusOrminus: "btn btn-info float-right feather icon-plus" })
  }
  const handlechangeImage = e => {
    console.log(e.target.files[0])
    if (e.target.files[0]) {
      setuserImage(e.target.files[0])
    }
  }
  let userPermessions = [
    {
      name: "Dashboard",
      icon: "ti-home",
      path: "/dashboard",
    },
    {
      name: "CRM",
      icon: "ti-bookmark-alt",
      path: "",
      subMenu: [
        {
          name: "Customers",
          path: "/customers",
        },
      ],
    },
    {
      name: "HRM",
      icon: "ti-package",
      path: "",
      subMenu: [
        {
          name: "Enployee",
          path: "/employee",
        },
      ],
    },

    {
      name: "Users",
      icon: "fas fa-user",
      path: "",
      subMenu: [
        {
          name: "Users",
          path: "/users",
        },
        {
          name: "Roles",
          path: "/roles",
        },
        {
          name: "Activity",
          path: "/activity",
        },
      ],
    },

    {
      name: "Reports",
      icon: "ti-view-grid",
      path: "",
      subMenu: [
        {
          name: "Customer Report",
          path: "/customerReport",
        },
        {
          name: "Users Report",
          path: "/usersReport",
        },
      ],
    },
  ]

  return (
    <attendenceContext.Provider
      value={{
        collapse,
        attendenceObj,
        isOpen,
        editCondition,
        tog_standard,
        HandleSubmitBtn,
        handleSubmitFormik,
        handleChange,
        handleEdit,
        handleCheckBoxChange,
        collapseToggle,
        btnCancelTogle,
        userPermessions,
        enrollmentData,
        setuserImage,
        setattendenceObj,
        handlechangeImage,
        userImage,
        setisFits,
        isFits,
        initialValues,
        teachersData,
        tempClassData,
        classData,
        setclassData,
        setenrollmentData,
        TempenrollmentData,
        attendenceList,
        disabled,
        load,
        setsweetAlertConfirm,
        sweetAlertConfirm,
        handleDelete,
        deletefunc,
        fetchAll,
        editattenceObj,
        handleSubmitEdit,
        handleChangeEdit,
      }}
    >
      {props.children}
    </attendenceContext.Provider>
  )
}

export default AttendenceContextProvider
