import { AvField, AvForm } from "availity-reactstrap-validation"
import axios from "axios"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import React, { useEffect, useState } from "react"
import "react-image-lightbox/style.css"
import MetaTags from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Container,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import useSwr, { mutate } from "swr"
import "toastr/build/toastr.min.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../Dashboard/datatables.scss"

import SweetAlert from "react-bootstrap-sweetalert"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { deletefunc, postfunc } from "../../services/mainService"
const Roles = () => {
  const [isFits, setisFits] = useState(false)
  const [userImage, setuserImage] = useState()
  const [menu, setMenu] = useState(false)
  const [usersObj, setusersObj] = useState({
    userRole: "",
    permissions: [],
  })
  const [isRight, setIsRight] = useState(false)
  const [editCondition, setEditCondition] = useState(false)
  const [collapse, setcollapse] = useState(false)
  const [Icon, setIcon] = useState({
    iconPlusOrminus: "btn btn-info float-right feather icon-plus",
  })
  const [isOpen, setIsOpen] = useState(false)
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const [sweetAlertConfirm, setsweetAlertConfirm] = useState(false)
  const [UserPermissions, setUserPermissions] = useState([])
  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT
  const epiEndPointDecoration = process.env.REACT_APP_DECORATION_ENDPOINT

  async function fetchAll(path) {
    let brandType = sessionStorage.getItem("brandType")
    if (brandType == "decoration") {
      epiEndPoint = epiEndPointDecoration
    }
    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  const { data: rolesList, error } = useSwr("roles", fetchAll)
  let Roles = []
  let chechArray = Array.isArray(rolesList)
  if (chechArray == true) {
    Roles = rolesList
  }
  // console.log("Users",Users);

  const tog_standard = () => {
    setIsOpen(!isOpen)
  }

  let userPermessions = [
    {
      name: "Dashboard",
      icon: "ti-home",
      path: "/dashboard",
    },
    {
      name: "Courses",
      icon: "fas fa-book-open",
      path: "",
      subMenu: [
        {
          name: "courses",
          path: "/courses",
        },
        {
          name: "subject",
          path: "/subject",
        },
        {
          name: "Teachers",
          path: "/teacher",
        },
        {
          name: "Class",
          path: "/class",
        },
      ],
    },
    {
      name: "Students",
      icon: "ion ion-md-contacts",
      path: "",
      subMenu: [
        {
          name: "students",
          path: "/student",
        },
        {
          name: "Enrolllment",
          path: "/stdenrollment",
        },
      ],
    },
    {
      name: "Inventory",
      icon: "ti-package",
      path: "",
      subMenu: [
        {
          name: "Supplier",
          path: "/supplier",
        },
        {
          name: "Stock",
          path: "/stock",
        },
        {
          name: "Purchase",
          path: "/purchase",
        },
        {
          name: "Sales",
          path: "/sales",
        },
        {
          name: "CustomInvoice",
          path: "/customInvoice",
        },
        {
          name: "SalesReceipts",
          path: "/salesReceipts",
        },
      ],
    },
    {
      name: "Service",
      icon: "ion ion-ios-apps",
      path: "",
      subMenu: [
        {
          name: "customers",
          path: "/customer",
        },
        {
          name: "Service",
          path: "/service",
        },
        {
          name: "service Receipt",
          path: "/servicereceipt",
        },
      ],
    },
    {
      name: "Finance",
      icon: "ion ion-md-cash",
      path: "",
      subMenu: [
        {
          name: "Fee",
          path: "/fee",
        },
        {
          name: "Receipt",
          path: "/receipt",
        },
        {
          name: "income",
          path: "/income",
        },
        {
          name: "expense",
          path: "/expense",
        },
      ],
    },
    {
      name: "Exam",
      icon: "far fa-list-alt",
      path: "",
      subMenu: [
        {
          name: "marks",
          path: "/marks",
        },
      ],
    },
    {
      name: "Attendence",
      icon: "ti-bookmark-alt",
      path: "",
      subMenu: [
        {
          name: "add attendence",
          path: "/attendence",
        },
        {
          name: "attendence list",
          path: "/attendencelist",
        },
      ],
    },
    {
      name: "HRM",
      icon: "ti-package",
      path: "",
      subMenu: [
        {
          name: "Employee",
          path: "/employee",
        },
        {
          name: "payroll",
          path: "/payroll",
        },
      ],
    },

    {
      name: "Users",
      icon: "fas fa-user",
      path: "",
      subMenu: [
        {
          name: "Users",
          path: "/users",
        },
        {
          name: "roles",
          path: "/roles",
        },
        {
          name: "Activity",
          path: "/activity",
        },
      ],
    },

    {
      name: "Reports",
      icon: "ti-view-grid",
      path: "",
      subMenu: [
        {
          name: "Fee Report",
          path: "/feeReport",
        },
        {
          name: "Receipt Report",
          path: "/receiptReport",
        },
        {
          name: "Statements",
          path: "/statement",
        },
        {
          name: "attendence",
          path: "/attendencereport",
        },
        {
          name: "Enrolllment",
          path: "/enrollmentreport",
        },
      ],
    },
  ]
  let decorationUserPermissions = [
    {
      name: "Dashboard",
      icon: "ti-home",
      path: "/decorationDashboard",
    },
    {
      name: "Branch",
      icon: "fas fa-book-open",
      path: "/branch",
    },
    {
      name: "Customers",
      icon: "ion ion-md-contacts",
      path: "/customer",
    },
    {
      name: "Service",
      icon: "ion ion-ios-apps",
      path: "",
      subMenu: [
        {
          name: "Service",
          path: "/service",
        },
        {
          name: "service Receipt",
          path: "/servicereceipt",
        },
      ],
    },

    {
      name: "Manage Activity",
      icon: "ti-bookmark-alt",
      path: "",
      subMenu: [
        {
          name: "Tasks",
          path: "/tasks",
        },
        {
          name: "Performance",
          path: "/perfomance",
        },
      ],
    },

    {
      name: "Inventory",
      icon: "ti-package",
      path: "",
      subMenu: [
        {
          name: "Supplier",
          path: "/supplier",
        },
        {
          name: "Stock",
          path: "/stock",
        },
        {
          name: "Purchase",
          path: "/purchase",
        },
      ],
    },

    {
      name: "Sales",
      icon: "far fa-list-alt",
      path: "",
      subMenu: [
        {
          name: "Sales",
          path: "/sales",
        },
        {
          name: "CustomInvoice",
          path: "/customInvoice",
        },
        {
          name: "SalesReceipts",
          path: "/salesReceipts",
        },
      ],
    },
    {
      name: "Transections",
      icon: "far fa-list-alt",
      path: "",
      subMenu: [
        {
          name: "Income",
          path: "/income",
        },
        {
          name: "Expense",
          path: "/expense",
        },
      ],
    },
    {
      name: "HRM",
      icon: "ti-package",
      path: "",
      subMenu: [
        {
          name: "Employee",
          path: "/employee",
        },
        {
          name: "payroll",
          path: "/payroll",
        },
      ],
    },

    {
      name: "Users",
      icon: "fas fa-user",
      path: "",
      subMenu: [
        {
          name: "Users",
          path: "/users",
        },
        {
          name: "roles",
          path: "/roles",
        },
      ],
    },

    {
      name: "Note",
      icon: "ion ion-ios-apps",
      path: "",
      subMenu: [
        {
          name: "add note",
          path: "/note",
        },
      ],
    },

    {
      name: "Reports",
      icon: "ti-view-grid",
      path: "",
      subMenu: [
        {
          name: " Stock Report",
          path: "/stockRport",
        },
        {
          name: "Sales Report",
          path: "/salesReport",
        },
        {
          name: "Statements",
          path: "/statement",
        },
        {
          name: "Service Dept Report",
          path: "/serviceReceiptReport",
        },
        {
          name: "Service Report",
          path: "/Service Report",
        },
        {
          name: "Receipt  Report",
          path: "/receiptReport",
        },
      ],
    },
  ]

  useEffect(() => {
    let brandType = sessionStorage.getItem("brandType")
    if (brandType == "decoration") {
      setUserPermissions(decorationUserPermissions)
    } else {
      setUserPermissions(userPermessions)
    }
  }, [])

  const HandleSubmitBtn = async e => {
    try {
      setdisabled(true)
      setload(true)

      //  return console.log(usersObj)

      let userroles = []
      if (usersObj.permissions) {
        usersObj.permissions.forEach(path => {
          let route = UserPermissions.find(item => item.path === path)
          if (!route) {
            UserPermissions.find(item => {
              let current_submenu = item.subMenu?.find(
                submenu => submenu.path === path
              )
              if (current_submenu) {
                if (userroles.find(usr => usr.name == item.name)) {
                  userroles.map(role => {
                    if (role.name == item.name) {
                      role.subMenu.push(current_submenu)
                    }
                  })
                } else {
                  let current_route = { ...item }
                  current_route.subMenu = current_route.subMenu.filter(
                    item => item.path === path
                  )

                  userroles.push(current_route)
                }
              }
            })
          } else {
            userroles.push(route)
          }
        })
      }

      let mainobj = {
        role: usersObj.userRole,
        permissions: userroles,
      }
      //  return console.log(mainobj)
      const { data } = await postfunc("roles", mainobj, headers)
      const { status, message, info } = { ...data }
      if (status) {
        setdisabled(false)
        setload(false)
        toast.success(message, {
          theme: "colored",
        })
        mutate("roles", fetchAll)
        setusersObj({
          userRole: "",
          permissions: [],
        })
        // notify(message, "success");
        setIsOpen(false)
        setcollapse(false)
      } else {
        setdisabled(false)
        setload(false)
        toast.warning(message, {
          theme: "colored",
        })
        // notify(message, "warning");
      }
    } catch (error) {
      setdisabled(false)
      setload(false)
      // console.log(error.response)
      // notify(error.message, "error");
      toast.error(error.message, {
        theme: "colored",
      })
    }
  }
  const handleChange = e => {
    // console.log(e.target.name + " ", e.target.value);
    setusersObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const handleDelete = async transData => {
    let data = { ...transData }
    setsweetAlertConfirm(true)
    setusersObj(data)
  }
  const viewModel = transData => {
    let data = { ...transData }
    setusersObj({
      userRole: data.role,
      permissions: data.permissions,
    })
    setIsOpen(true)
  }
  const handleCheckBoxChange = e => {
    // console.log(e.target.value)
    if (e.target.checked) {
      setusersObj(prev => {
        let new_permission = [...prev.permissions, e.target.value]
        return {
          ...prev,
          [e.target.name]: e.target.value,
          permissions: new_permission,
        }
      })
    } else {
      setusersObj(prev => {
        let new_permission = prev.permissions.filter(p => p !== e.target.value)
        return {
          ...prev,
          [e.target.name]: e.target.value,
          permissions: new_permission,
        }
      })
    }
  }
  const collapseToggle = () => {
    setcollapse(!collapse)
    if (collapse) {
      setIcon({
        iconPlusOrminus: "btn btn-info float-right feather icon-plus",
      })
    } else {
      setIcon({
        iconPlusOrminus: "btn btn-info float-right feather icon-minus",
      })
    }
  }
  const btnCancelTogle = () => {
    setcollapse(!collapse)
    setIcon({ iconPlusOrminus: "btn btn-info float-right feather icon-plus" })
  }
  const notify = (message, type) => toast[type](message)

  const data = {
    columns: [
      {
        label: "role",
        field: "role",
        sort: "asc",
        width: 150,
      },

      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },

      {
        label: "view role",
        field: "viewrole",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: Roles?.map(hos => {
      const data = { ...hos }
      data.date = moment(data.createdAt).format("lll")

      data.viewrole = (
        <div>
          <button
            onClick={() => viewModel(hos)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="fas fa-eye font-size-20 "
            ></i>
          </button>
        </div>
      )
      data.action = (
        <div>
          <button
            onClick={() => handleDelete(hos)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#EC4561",
              }}
              className="fas fa-trash-alt font-size-20 "
            ></i>
          </button>
        </div>
      )
      return data
    }),
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Roles | easy soft</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs
              maintitle="Users"
              title="Roles"
              breadcrumbItem="roles"
            />
            {sweetAlertConfirm ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={async () => {
                  const { data } = await deletefunc(`roles`, usersObj, headers)
                  const { status, message } = data
                  if (status) {
                    setsweetAlertConfirm(false)
                    toast.success(message, {
                      theme: "colored",
                    })
                    mutate("roles", fetchAll)
                    setusersObj({
                      userRole: "",
                      permissions: [],
                    })
                  } else {
                    toast.error(message, {
                      theme: "colored",
                    })
                  }
                }}
                onCancel={() => setsweetAlertConfirm(false)}
              >
                You won't be able to revert this!
              </SweetAlert>
            ) : null}
            <Row>
              <Col sm={12}>
                <Card className="position-sticky">
                  <CardHeader style={{ background: "#14213D", color: "white" }}>
                    {/* <h4>User Roles</h4> */}
                    <Button
                      color="dark"
                      outline
                      className="float-end text-white  waves-effect waves-light"
                      onClick={collapseToggle}
                    >
                      Add New Role
                    </Button>
                  </CardHeader>

                  <Collapse isOpen={collapse}>
                    <div id="basic-collapse">
                      <CardBody>
                        {/* <h5>Personal Information</h5>
                    <hr /> */}
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={HandleSubmitBtn}
                        >
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <AvField
                                  type="text"
                                  name="userRole"
                                  label="userRole"
                                  value={usersObj.userRole}
                                  onChange={e => handleChange(e)}
                                  className="form-control"
                                  errorMessage="please userRole"
                                  placeholder="Enter userRole"
                                  validate={{ required: { value: true } }}
                                ></AvField>
                              </FormGroup>
                            </Col>
                            {/* <Col md="6">
                              <FormGroup>
                                <AvField
                                  type="number"
                                  name="phone"
                                  label="Phone"
                                  value={usersObj.phone}
                                  onChange={e => handleChange(e)}
                                  className="form-control"
                                  errorMessage="please Enter Phone"
                                  placeholder="Enter Phone"
                                  validate={{ required: { value: true } }}
                                ></AvField>
                              </FormGroup>
                            </Col> */}
                          </Row>

                          <hr></hr>
                          <h5>User Permissions</h5>
                          <Row className="mt-4 ml-4">
                            {UserPermissions.map((item, index) =>
                              item.subMenu && item.subMenu.length ? (
                                <Col md="2">
                                  <p>{item.name}</p>
                                  {item.subMenu?.map((sub, sub_index) => (
                                    <FormGroup key={sub_index}>
                                      <div className="checkbox checkbox-primary ">
                                        <input
                                          onChange={e =>
                                            handleCheckBoxChange(e)
                                          }
                                          type="checkbox"
                                          name="permissions"
                                          className="form-check-input"
                                          value={sub.path}
                                          //   checked={true}
                                          // value={usersObj.permissions[sub.path]}
                                          id={"checkbox" + index + sub_index}
                                        />
                                        <label
                                          htmlFor={
                                            "checkbox" + index + sub_index
                                          }
                                          className="cr"
                                        >
                                          {sub.name}
                                        </label>
                                      </div>
                                    </FormGroup>
                                  ))}
                                </Col>
                              ) : (
                                // <hr />

                                <Col key={index}>
                                  <FormGroup key={index}>
                                    <div className="checkbox checkbox-primary form-check   ">
                                      <input
                                        onChange={e => handleCheckBoxChange(e)}
                                        type="checkbox"
                                        className="form-check-input"
                                        name="permissions"
                                        value={item.path}
                                        // checked={true}
                                        // value={usersObj.permissions[item.path]}
                                        id={"checkbox" + index}
                                      />
                                      <label
                                        htmlFor={"checkbox" + index}
                                        className="cr"
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                  </FormGroup>
                                </Col>
                              )
                            )}
                          </Row>
                          {/* edit row */}
                          {/* <Row className="mt-4 ml-4">
                            {userPermessions.map((item, index) =>
                            {usersObj?.permissions.map((value, indexes) =>{


                              item.subMenu && item.subMenu.length ? (

                                <Col md="2">
                                  <p>{item.name}</p>
                                  {item.subMenu?.map((sub, sub_index) => (
                                    <FormGroup key={sub_index}>
                                      <div className="checkbox checkbox-primary ">
                                        <input
                                          onChange={e =>
                                            handleCheckBoxChange(e)
                                          }
                                          type="checkbox"
                                          name="permissions"
                                          className="form-check-input"
                                          value={sub.path}
                                        //   checked={true}
                                          // value={usersObj.permissions[sub.path]}
                                          id={"checkbox" + index + sub_index}
                                        />
                                        <label
                                          htmlFor={
                                            "checkbox" + index + sub_index
                                          }
                                          className="cr"
                                        >
                                          {sub.name}
                                        </label>
                                      </div>
                                    </FormGroup>
                                  ))}

                                </Col>
                                // <hr />


                              ) : (
                                <Col key={index}>
                                  <FormGroup key={index}>
                                    <div className="checkbox checkbox-primary form-check   ">
                                      <input
                                        onChange={e => handleCheckBoxChange(e)}
                                        type="checkbox"
                                        className="form-check-input"
                                        name="permissions"
                                        value={item.path}
                                        // checked={true}
                                        // value={usersObj.permissions[item.path]}
                                        id={"checkbox" + index}
                                      />
                                      <label
                                        htmlFor={"checkbox" + index}
                                        className="cr"
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                  </FormGroup>
                                </Col>
                              )
                            })}
                            )}
                          </Row> */}

                          <Button
                            className="btn btn-success mt-4 m-2 "
                            type="submit"
                            disabled={disabled}
                          >
                            {load ? "saving..." : "Save Roles"}
                          </Button>
                          <Button
                            onClick={btnCancelTogle}
                            className="btn btn-danger mt-4 m-2 "
                            type="button"
                          >
                            Close
                          </Button>
                        </AvForm>

                        <hr />
                      </CardBody>
                    </div>
                  </Collapse>
                </Card>
              </Col>
            </Row>

            <ToastContainer />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="align-items-center  mb-3">
                      <Col md={8}>
                        <CardTitle className="h4">users Table </CardTitle>
                      </Col>

                      <Col md="4">
                        {/* <div className="float-end d-none d-md-block">
                          <Button
                            onClick={tog_standard}
                            style={{ background: "#0A928C", color: "white" }}
                            className=" dropdown-toggle waves-effect waves-light"
                          >
                            <i className="ion ion-md-add me-2"></i> Add User
                          </Button>
                        </div> */}
                      </Col>
                    </Row>

                    <MDBDataTable responsive striped bordered data={data} />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Modal isOpen={isOpen} toggle={tog_standard}>
              <ModalHeader
                style={{
                  background: "#14213D",
                  color: "white",
                  border: "#0A928C",
                }}
                toggle={tog_standard}
              >
                {" "}
                <p>user roles</p>
              </ModalHeader>
              <AvForm
                className="needs-validation"
                //   onValidSubmit={HandleSubmitBtn}
              >
                <ModalBody>
                  <h5>{usersObj.userRole} Permissions</h5>
                  <hr></hr>
                  <Row className="mt-2 ml-2">
                    {usersObj.permissions.map((item, index) =>
                      item.subMenu && item.subMenu.length ? (
                        <Col md="3">
                          <p>{item.name}</p>
                          {item.subMenu?.map((sub, sub_index) => (
                            <FormGroup key={sub_index}>
                              <div className="checkbox checkbox-primary ">
                                <input
                                  onChange={e => handleCheckBoxChange(e)}
                                  type="checkbox"
                                  name="permissions"
                                  className="form-check-input"
                                  value={sub.path}
                                  checked={true}
                                  // value={usersObj.permissions[sub.path]}
                                  id={"checkbox" + index + sub_index}
                                />
                                <label
                                  htmlFor={"checkbox" + index + sub_index}
                                  className="cr"
                                >
                                  {sub.name}
                                </label>
                              </div>
                            </FormGroup>
                          ))}
                        </Col>
                      ) : (
                        // <hr />

                        <Col key={index}>
                          <FormGroup key={index}>
                            <div className="checkbox checkbox-primary form-check   ">
                              <input
                                onChange={e => handleCheckBoxChange(e)}
                                type="checkbox"
                                className="form-check-input"
                                name="permissions"
                                value={item.path}
                                checked={true}
                                // value={usersObj.permissions[item.path]}
                                id={"checkbox" + index}
                              />
                              <label
                                htmlFor={"checkbox" + index}
                                className="cr"
                              >
                                {item.name}
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      )
                    )}
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    color="danger"
                    onClick={tog_standard}
                    className="waves-effect"
                  >
                    Close
                  </Button>
                </ModalFooter>
              </AvForm>
            </Modal>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Roles
