import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import axios from "axios"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
// import { FetchTrensections } from "./TransectionApi";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//Import Components
import axios from "axios"
import jwtDecode from "jwt-decode"
import SweetAlert from "react-bootstrap-sweetalert"
import { useLocation } from "react-router-dom"
import Select from "react-select"
import useSwr, { mutate } from "swr"
import Auhtentication from "../../authMidlleware/userMiddleWare"

import {
  GetAll,
  deletefunc,
  postfunc,
  putfunc,
} from "../../services/mainService"
const ServiceReceipt = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [editCondition, setEditCondition] = useState(false)
  const [serviceReceiptObj, setserviceReceiptObj] = useState({
    serviceID: "",
    customerID: "",
    phonePaid: "",
    phoneReceipt: "",
    AmountPaid: "",
    datePaid: "",
  })
  const [customers, setcustomers] = useState([])
  const [service, setservice] = useState([])
  const [serviceTemp, setserviceTemp] = useState([])
  const [selectedCustomer, setselectedCustomer] = useState({})
  const [selectedService, setselectedService] = useState({})

  const [currentUser, setcurrentUser] = useState({})
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const [sweetAlertConfirm, setsweetAlertConfirm] = useState(false)
  const [deleteObj, setdeleteObj] = useState({})

  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT
  const epiEndPointDecoration = process.env.REACT_APP_DECORATION_ENDPOINT

  async function fetchAll(path) {
    let brandType = sessionStorage.getItem("brandType")
    if (brandType == "decoration") {
      epiEndPoint = epiEndPointDecoration
    }

    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  useEffect(() => {
    async function onload() {
      const { data: customerslist } = await GetAll("customers", headers)
      const { data: servicelist } = await GetAll("service", headers)
      let checkcustomers = Array.isArray(customerslist)
      let checkcService = Array.isArray(servicelist)
      if (checkcustomers) {
        setcustomers(customerslist)
      }
      if (checkcService) {
        setservice(servicelist)
        setserviceTemp(servicelist)
      }
      const token = localStorage.getItem("token")
      if (token) {
        const user = jwtDecode(token)
        setcurrentUser(user)
      }
    }
    onload()
  }, [])

  let serviceReceiptData = []
  const { data: serviceReceiptlist } = useSwr("serviceReceipt", fetchAll)

  let chechArray = Array.isArray(serviceReceiptlist)
  if (chechArray == true) {
    serviceReceiptData = serviceReceiptlist
  }
  const tog_standard = () => {
    setIsOpen(!isOpen)
    setserviceReceiptObj({
      serviceID: "",
      customerID: "",
      phonePaid: "",
      phoneReceipt: "",
      AmountPaid: "",
      datePaid: "",
    })
    setselectedCustomer({})
    setselectedCustomer({})
    setEditCondition(false)
  }
  const breadcrumbItems = [
    { title: "minibank", link: "#" },
    { title: "customer", link: "#" },
  ]
  const handleChange = e => {
    setserviceReceiptObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const notify = (message, type) => toast[type](message)

  const HandleSubmitBtn = async e => {
    try {
      setdisabled(true)
      setload(true)
      e.preventDefault()
      const token = localStorage.getItem("token")

      if (editCondition) {
        const { data } = await putfunc(
          "serviceReceipt",
          serviceReceiptObj,
          headers
        )
        console.log(data.info)
        const { status, message, info } = { ...data }
        if (status) {
          mutate("serviceReceipt", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setEditCondition(false)
          setserviceReceiptObj({
            serviceID: "",
            customerID: "",
            phonePaid: "",
            phoneReceipt: "",
            AmountPaid: "",
            datePaid: "",
          })
        }
      } else {
        if (Object.keys(selectedCustomer).length === 0)
          return toast.warning("please choose a customer")
        if (Object.keys(selectedService).length === 0)
          return toast.warning("please choose a Service")

        serviceReceiptObj.customerID = selectedCustomer.value
        serviceReceiptObj.serviceID = selectedService.value
        serviceReceiptObj.userID = currentUser._id

        let currentService = service.find(
          serv => serv._id == selectedService.value
        )

        if (serviceReceiptObj.AmountPaid > currentService.Balance) {
          return toast.error(
            `you can only pay ${currentService.Balance} or less then`
          )
        }

        // return console.log("serviceReceiptObj", serviceReceiptObj)/

        const { data } = await postfunc(
          "serviceReceipt",
          serviceReceiptObj,
          headers
        )
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          setIsOpen(false)

          notify(message, "success")
          mutate("serviceReceipt", fetchAll)
          setselectedCustomer({})
          setselectedCustomer({})
          setserviceReceiptObj({
            serviceID: "",
            customerID: "",
            phonePaid: "",
            phoneReceipt: "",
            AmountPaid: "",
            datePaid: "",
          })
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      }
      setdisabled(false)
      setload(false)
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
      setdisabled(false)
      setload(false)
    }
  }

  const handleEdit = async transData => {
    let data = { ...transData }
    setEditCondition(true)
    setserviceReceiptObj(data)
    setIsOpen(true)
    setselectedCustomer({
      label: data.customerID?.name + "-" + data.customerID.code,
      value: data.customerID?._id,
    })
  }
  const ServiceIDOnachnge = e => {
    try {
      //   console.log(e)
      if (e.value) {
        setselectedService(e)
        const currentServiceSelectedObj = serviceTemp.find(
          service => service._id == e.value
        )
        setserviceReceiptObj({
          serviceID: serviceReceiptObj.serviceID,
          customerID: serviceReceiptObj.customerID,
          phonePaid: serviceReceiptObj.phonePaid,
          phoneReceipt: serviceReceiptObj.phoneReceipt,
          AmountPaid: currentServiceSelectedObj.Balance,
          datePaid: serviceReceiptObj.datePaid,
        })
      }
    } catch (error) {
      console.log(e)
      console.log(error)
    }
  }
  const CustomerIDOnachnge = e => {
    try {
      //   console.log(e)
      if (e.value) {
        setselectedCustomer(e)
        const serviceList = serviceTemp.filter(
          service => service.customerID?._id == e.value && service.Balance > 0
        )
        console.log("serviceList", serviceList)
        setservice(serviceList)
      }
    } catch (error) {
      console.log(e)
      console.log(error)
    }
  }
  const UserData = {
    columns: [
      {
        label: "No",
        field: "receiptNo",
        sort: "asc",
        width: 40,
      },
      {
        label: "customer",
        field: "customerName",
        sort: "asc",
        width: 40,
      },
      {
        label: "Service ",
        field: "serviceName",
        sort: "asc",
        width: 200,
      },
      {
        label: "AmountPaid",
        field: "AmountPaid",
        sort: "asc",
        width: 60,
      },
      {
        label: "phonePaid",
        field: "phonePaid",
        sort: "asc",
        width: 50,
      },
      {
        label: "phoneReceipt",
        field: "phoneReceipt",
        sort: "asc",
        width: 50,
      },
      {
        label: "Created Date",
        field: "date",
        sort: "asc",
        width: 270,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 50,
      },
    ],
    rows: serviceReceiptData?.map(cust => {
      const data = { ...cust }
      data.customerName = data.customerID?.name
      data.serviceName = data.serviceID?.serviceName

      data.AmountPaid = "$" + data.AmountPaid

      data.date = moment(data.createdAt).format("lll")

      if (data.Status == "percialPaid") {
        data.Status = (
          <div className="badge bg-info  font-size-15 badge-pill mr-2">
            {data.Status}
          </div>
        )
      } else if (data.Status == "fullPaid") {
        data.Status = (
          <div className="badge bg-success font-size-15 badge-pill mr-2">
            {data.Status}
          </div>
        )
      } else {
        data.Status = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.Status}
          </div>
        )
      }

      data.action = (
        <div>
          <button
            onClick={() => handleEdit(cust)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
          <button
            onClick={() => handleDelete(cust)}
            className="btn btn-white btn-sm mr-4"
          >
            <i className="dripicons-trash text-danger font-size-20"></i>
          </button>
        </div>
      )
      return data
    }),
  }
  const location = useLocation()
  Auhtentication(location.pathname)

  const handleDelete = data => {
    setdeleteObj(data)
    setsweetAlertConfirm(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Customers" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col sm={6} md={4} xl={3} className="mt-4">
              <div className="text-center"></div>
              <Modal isOpen={isOpen} toggle={tog_standard}>
                <ModalHeader
                  style={{
                    background: "#14213D",
                    color: "white",
                    border: "#0A928C",
                  }}
                  toggle={tog_standard}
                >
                  {" "}
                  <p>Add Service </p>
                </ModalHeader>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={HandleSubmitBtn}
                >
                  <ModalBody>
                    headers
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Select Customer</Label>
                          <Select
                            name="studentID"
                            value={selectedCustomer}
                            placeholder="Select a customer"
                            // isClearable={true}
                            isSearchable={true}
                            onChange={e => CustomerIDOnachnge(e)}
                            options={customers?.map((cust, index) => {
                              return {
                                label: cust.name + "-" + cust.code,
                                value: cust._id,
                                key: index,
                              }
                            })}
                          ></Select>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Select Service</Label>
                          <Select
                            name="studentID"
                            value={selectedService}
                            required
                            // isClearable={true}
                            isSearchable={true}
                            onChange={e => ServiceIDOnachnge(e)}
                            options={service?.map((service, index) => {
                              return {
                                label: service.serviceName,
                                value: service._id,
                                key: index,
                              }
                            })}
                          ></Select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            type="number"
                            label="AmountPaid"
                            name="AmountPaid"
                            value={serviceReceiptObj.AmountPaid}
                            onChange={e => handleChange(e)}
                            errorMessage="enter  AmountPaid"
                            placeholder="Enter Amount Paid"
                            validate={{
                              required: { value: true },
                            }}
                          ></AvField>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <AvField
                            type="number"
                            label="phonePaid"
                            name="phonePaid"
                            value={serviceReceiptObj.phonePaid}
                            onChange={e => handleChange(e)}
                            errorMessage="enter  phonePaid"
                            placeholder="Enter phone Paid "
                            validate={{
                              required: { value: true },
                            }}
                          ></AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            type="number"
                            label="phoneReceipt"
                            name="phoneReceipt"
                            value={serviceReceiptObj.phoneReceipt}
                            onChange={e => handleChange(e)}
                            errorMessage="enter  phoneReceipt"
                            placeholder="Enter phoneReceipt Paid"
                            validate={{
                              required: { value: true },
                            }}
                          ></AvField>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <AvField
                            type="datetime-local"
                            label="datePaid"
                            name="datePaid"
                            value={serviceReceiptObj.datePaid}
                            onChange={e => handleChange(e)}
                            errorMessage="enter  datePaid"
                            placeholder="Enter datePaid "
                            validate={{
                              required: { value: true },
                            }}
                          ></AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="danger"
                      onClick={tog_standard}
                      className="waves-effect"
                    >
                      Close
                    </Button>

                    <Button
                      type="submit"
                      style={{
                        background: "#14213D",
                        color: "white",
                      }}
                      disabled={disabled}
                      className="waves-effect waves-light"
                    >
                      {load ? "saving..." : " Save Service"}
                    </Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
            </Col>
          </Row>
          <ToastContainer />

          {/* data table Row  */}
          <Row>
            <Col xs={12}>
              <Card>
                {/* <CardHeader  style={{background:"#14213D",color:"white"}}>
                 Here you can register your customer and also get's customers record.
                </CardHeader> */}
                <CardBody>
                  <Row className="mb-2">
                    <Col sm={6} md={6} xl={10}>
                      <h4 className="card-title">Service Receipt Table </h4>
                    </Col>
                    <Col sm={6} md={6} xl={2}>
                      <Button
                        type="button"
                        onClick={tog_standard}
                        style={{ background: "#14213D", color: "white" }}
                        className="waves-effect waves-light float-right"
                      >
                        <i className="ri-add-line"></i> Add Service Receipt
                      </Button>
                    </Col>
                  </Row>

                  <MDBDataTable responsive striped bordered data={UserData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {sweetAlertConfirm ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={async () => {
                try {
                  // return  console.log(IncomeObj)
                  const { data } = await deletefunc(
                    "serviceReceipt",
                    deleteObj,
                    headers
                  )
                  const { status, message } = { ...data }
                  if (status) {
                    toast.success(message)
                    setsweetAlertConfirm(false)
                    mutate("serviceReceipt", fetchAll)
                  } else {
                    toast.error(message)
                  }
                } catch (error) {
                  toast.error(error.message)
                }
              }}
              onCancel={() => setsweetAlertConfirm(false)}
            >
              Are you sure to delete this data
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ServiceReceipt
