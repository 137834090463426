import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import React, { useRef, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useReactToPrint } from "react-to-print"
import { Card, CardBody, Col, FormGroup, Label, Modal, Row } from "reactstrap"
import useSWR, { mutate } from "swr"
// import headerImage from "../../assets/images/softwarelogo.png";
import axios from "axios"
import { useEffect } from "react"
// import "./datatables.scss"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { ToastContainer, toast } from "react-toastify"
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import { ToastContainer, toast } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"
import { useLocation } from "react-router-dom"
import Auhtentication from "../../authMidlleware/userMiddleWare"

const Purchase = () => {
  const [isopen, setisopen] = useState(false)
  const [isupdate, setisupdate] = useState(false)
  const [loading, setloading] = useState(false)

  const tog_standard = () => {
    setisopen(!isopen)
    setisupdate(false)
    if (!isupdate) {
      setupdatedata("")
    }
  }

  const location = useLocation()
  Auhtentication(location.pathname)
  const token = sessionStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }

  let url = process.env.REACT_APP_DECORATION_ENDPOINT

  // get data
  async function fetchr() {
    const { data } = await axios.get(`${url}/purchase`, headers)
    return data
  }
  const { data: purchaseList, error } = useSWR(`${url}/purchase`, fetchr)

  let purchaseData = []
  if (Array.isArray(purchaseList)) {
    purchaseData = purchaseList
  }

  const [updatedata, setupdatedata] = useState([])
  const [ticketObj, setticketObj] = useState({
    itemID: "",
    Quantity: 0,
    purchasePrice: 0,
    salingPrice: 0,
  })

  const handleChange = e => {
    setticketObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  //   const handleChangeNumbers=e=>{
  //     setticketObj(prev => {
  //         return { ...prev, [e.target.name]:parseInt(e.target.value)}
  //     }
  //     )}

  // tiket post
  const handlerecpost = async (event, value) => {
    // console.log(value)
    try {
      //   setisupdate(false)

      if (SelectDrug.value == undefined) {
        return toast.error("plz select drug")
      }

      if (isupdate) {
        ticketObj.itemID = SelectDrug.value
        // value.itemID=SelectDrug.value
        //  return  console.log("ticketObj",ticketObj)

        // return console.log("value",value)
        setloading(true)
        const { data } = await axios.put(
          `${url}/purchase/${updateid}`,
          ticketObj,
          headers
        )
        console.log(updatedata)
        // console.log(patId)
        if (data.status == "success") {
          setisopen(false)
          setisupdate(false)

          toast.success(data.message)
          mutate(`${url}/purchase`, fetchr)
        } else {
          toast.error(data.message)
        }
        setloading(false)
      } else {
        ticketObj.itemID = SelectDrug.value

        setloading(true)
        // return console.log("purchuse obj",ticketObj)
        const { data } = await axios.post(`${url}/purchase`, ticketObj, headers)
        console.log(ticketObj)

        if (data.status == "success") {
          toast.success(data.message)
          setisopen(false)
          mutate(`${url}/purchase`, fetchr)
        } else {
          toast.error(data.message)
        }
        setloading(false)
      }
      //   if(!isupdate){
      // setupdatedata([])
    } catch (error) {
      toast.error(error.message)
      setloading(false)
    }
    //   }
  }
  // put or update
  const [updateid, setupdateid] = useState([])
  const [Date, setDate] = useState("")

  const handleEdit = updatadr => {
    let date = { ...updatadr }
    setSelectDrug({
      label: updatadr.itemID?.itemName,
      value: updatadr.itemID?._id,
    })
    setisupdate(true)
    setisopen(!isopen)
    setupdateid(updatadr._id)
    setupdatedata(updatadr)
    // console.log(updatadr)
    setpatient_id(updatadr.itemID._id)
    // console.log(patId)
    setpatName(updatadr.itemID.itemName)
    setticketObj(updatadr)
  }

  // delete data
  const [deletedr, setdeletedr] = useState([])
  const [deletealert, setdeletealert] = useState(false)

  const handledoctordelete = deletedata => {
    setdeletedr(deletedata._id)
    setdeletealert(true)
  }
  const handledeletedoctor = async () => {
    // console.log("delte")
    try {
      const { data } = await axios.delete(
        `${url}/purchase/${deletedr}`,
        headers
      )
      //   console.log(data.status)
      setdeletealert(false)
      if (data.status === "success") {
        toast.success(data.message)

        mutate(`${url}/purchase`, fetchr)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  //get data pataint
  const [getdoctordata, setgetdoctordata] = useState([])
  //   console.log(getdoctordata)

  const [Drugs, setDrugs] = useState([])
  const [getsuplier, setsuplier] = useState([])
  const [patname, setpatName] = useState("")
  const [patient_id, setpatient_id] = useState("")

  useEffect(() => {
    async function onload() {
      const { data } = await axios.get(`${url}/stock`, headers, headers)
      if (Array.isArray(data)) {
        setDrugs(data)
      }
      const { data: supplierList } = await axios.get(`${url}/suplier`, headers)
      if (Array.isArray(supplierList)) {
        setsuplier(supplierList)
      }
    }
    onload()
  }, [])

  const [viewData, setviewData] = useState({})
  const [SelectDrug, setSelectDrug] = useState({})
  const [showModel, setshowModel] = useState(false)

  let togle = () => {
    setshowModel(!showModel)
  }
  let handleView = data => {
    console.log(data)
    setviewData(data)
    setshowModel(true)
  }
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "prescription",
  })
  // console.log("purchaseData",purchaseData)

  const data = {
    columns: [
      {
        label: "Suplier",
        field: "suplier",
        sort: "asc",
        width: 100,
      },
      {
        label: "Drug Name",
        field: "drug",
        sort: "asc",
        width: 100,
      },
      {
        label: "Quantity",
        field: "Quantity",
        sort: "asc",
        width: 100,
      },

      {
        label: "purchase Price",
        field: "purchasePrice",
        sort: "asc",
        width: 100,
      },
      {
        label: "Saling Price",
        field: "salingPrice",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "createdAt",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: purchaseData?.map(currentData => {
      let data = { ...currentData }

      data.drug = data.itemID?.itemName
      data.suplier = data.suplierId?.name
      // data.createdAt = moment(data.createdAt).format("YYYY-MM-DD")
      data.createdAt = moment(data.createdAt).format("lll")
      data.action = (
        <div className="d-flex">
          <button
            className="btn   text-primary "
            onClick={() => handleEdit(currentData)}
          >
            {" "}
            <i className="ion ion-md-create font-size-20 m-auto"></i>
          </button>
          <button
            className="btn btn-white  text-primary  mx-3 "
            onClick={() => handledoctordelete(data)}
          >
            {" "}
            <i className="ion ion-md-trash font-size-20 m-auto"></i>
          </button>
        </div>
      )
      return data
    }),
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs
          maintitle="Software Decoration"
          title="Invemtory"
          breadcrumbItem="Purchuse"
        />
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* post model start */}
                  <div className="row">
                    <div className="col-10"></div>
                    <div className="col-2">
                      <Row>
                        <Col sm={6} md={4} xl={3}>
                          <div className="my-4 text-center">
                            <button
                              type="button"
                              onClick={() => {
                                tog_standard()
                              }}
                              className="btn waves-effect waves-light"
                              style={{
                                backgroundColor: "#607d8e",
                                color: "white",
                              }}
                              data-toggle="modal"
                              data-target="#myModal"
                            >
                              ADD Purchase
                            </button>

                            <Modal
                              isOpen={isopen}
                              togle={() => {
                                tog_standard()
                              }}
                            >
                              <div className="modal-header">
                                <h5
                                  className="modal-title mt-0"
                                  id="myModalLabel"
                                >
                                  Purchase Model
                                </h5>
                              </div>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handlerecpost(e, v)
                                }}
                              >
                                <div className="modal-body">
                                  <Row>
                                    <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label>Suplier:</Label>
                                        <AvField
                                          name="suplierId"
                                          //   placeholder="Ref No"
                                          type="select"
                                          //   value={itemID}
                                          onChange={handleChange}
                                          value={updatedata.suplierId?._id}
                                          errorMessage="Choose Suplier"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="validationCustom02"
                                        >
                                          {isupdate && (
                                            <option
                                              value={updatedata.suplierId._id}
                                            >
                                              {updatedata.suplierId.name}
                                            </option>
                                          )}
                                          <option value="">
                                            Select Suplier
                                          </option>

                                          {getsuplier?.map(pat => {
                                            return (
                                              <option value={pat?._id}>
                                                {pat?.name}
                                              </option>
                                            )
                                          })}
                                        </AvField>
                                      </FormGroup>
                                    </Col>
                                    <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label> Item Name:</Label>
                                        {/* <AvField
                                          name="itemID"
                                        //   placeholder="Ref No"
                                          type="select"
                                        //   value={itemID}
                                        onChange={handleChange}
                                          // value={updatedata.itemID?._id}
                                          errorMessage="Choose Drug Name"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="validationCustom02"
                                        >

                                        <option value=''>Select Drug Name</option>

                                        {Drugs?.map((pat)=>{
                                            return(
                                        <option value={pat?._id}>{pat?.itemName}</option>
                                            )
                                        })}
                                        </AvField> */}

                                        <Select
                                          name="itemID"
                                          placeholder="select item "
                                          value={SelectDrug}
                                          // required
                                          onChange={event => {
                                            // console.log(event)
                                            setSelectDrug(event)
                                          }}
                                          options={Drugs?.map((drug, index) => {
                                            return {
                                              label: drug.itemName,
                                              value: drug._id,
                                              key: index,
                                            }
                                          })}
                                        ></Select>
                                      </FormGroup>
                                    </Col>
                                    <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label>Quantity:</Label>
                                        <AvField
                                          name="Quantity"
                                          placeholder="Quantity"
                                          type="number"
                                          onChange={handleChange}
                                          value={updatedata.Quantity}
                                          errorMessage="Enter Quantity"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="validationCustom02"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label>purchase Price:</Label>
                                        <AvField
                                          name="purchasePrice"
                                          placeholder="purchase Price"
                                          //   placeholder="Date In"
                                          type="number"
                                          value={updatedata.purchasePrice}
                                          onChange={handleChange}
                                          errorMessage="Enter Purchase Price"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="validationCustom02"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label>Salling Price:</Label>
                                        <AvField
                                          name="salingPrice"
                                          placeholder="Salling Price:"
                                          type="number"
                                          onChange={handleChange}
                                          value={updatedata.salingPrice}
                                          errorMessage="Enter Salling Price"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="validationCustom02"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      tog_standard()
                                    }}
                                    className="btn btn-secondary waves-effect"
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-primary waves-effect waves-light"
                                    disabled={loading}
                                  >
                                    {loading ? "Saving..." : "Save Purchase"}

                                    {/* setloading(false) */}
                                  </button>
                                </div>
                              </AvForm>
                            </Modal>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* sweetAlert */}
          {deletealert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={handledeletedoctor}
              onCancel={() => setdeletealert(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Purchase
