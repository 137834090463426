import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import axios from "axios"

import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap"
// import { FetchTrensections } from "./TransectionApi";
//Import Breadcrumb
import Select from "react-select"
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import axios from "axios"
import jwtDecode from "jwt-decode"
import { useLocation } from "react-router-dom"
import useSwr, { mutate } from "swr"
import Auhtentication from "../../authMidlleware/userMiddleWare"
import {
  GetAll,
  GetById,
  deletefunc,
  postfunc,
  putfunc,
} from "../../services/mainService"

const Complain = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [editCondition, setEditCondition] = useState(false)
  //   let currentDate = new Date().toJSON().slice(0, 10)
  const [complainObj, setcomplainObj] = useState({
    studentID: "",
    classID: "",
    description: "",
    complainDate: "",
    status: "",
    userID: "",
  })
  const [sweetAlertConfirm, setsweetAlertConfirm] = useState(false)

  const [Subjects, setSubjects] = useState([])
  const [subjectTemp, setsubjectTemp] = useState([])
  const [selectedStudent, setselectedStudent] = useState({})

  const [students, setstudents] = useState([])

  const [classData, setclassData] = useState([])
  const [classTempData, setclassTempData] = useState([])

  const [currentUser, setcurrentUser] = useState({})
  const [stdenrollemntData, setstdenrollemntData] = useState([])
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const [studentClasses, setstudentClasses] = useState([])
  const [statusList, setstatusList] = useState([
    "assigned",
    "accepted",
    "process",
    "completed",
  ])
  const [classload, setclassload] = useState(false)
  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT

  async function fetchAll(path) {
    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  useEffect(() => {
    async function onload() {
      const { data: studentlist } = await GetAll("student", headers)
      let checkstudent = Array.isArray(studentlist)
      if (checkstudent) {
        setstudents(studentlist)
      }
      const token = localStorage.getItem("token")
      if (token) {
        const user = jwtDecode(token)
        // user.userType = "teacher"
        setcurrentUser(user)
      }
    }
    onload()
  }, [])

  let marksData = []
  const { data: marksList } = useSwr("complain", fetchAll)
  let chechArray = Array.isArray(marksList)
  if (chechArray == true) {
    marksData = marksList
  }

  const tog_standard = () => {
    setload(false)
    setdisabled(false)
    setIsOpen(!isOpen)
    setcomplainObj({
      studentID: "",
      classID: "",
      description: "",
      complainDate: "",
      status: "",
      userID: "",
    })
    setEditCondition(false)
    setstudentClasses([])
    setselectedStudent({})
  }
  const breadcrumbItems = [
    { title: "minibank", link: "#" },
    { title: "customer", link: "#" },
  ]
  const handleChange = e => {
    setcomplainObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const notify = (message, type) => toast[type](message)

  const HandleSubmitBtn = async e => {
    try {
      e.preventDefault()
      const token = localStorage.getItem("token")
      setdisabled(true)
      setload(true)
      if (editCondition) {
        // return console.log("complainObj", complainObj)
        // complainObj._id = complainObj.studentID
        const { data } = await putfunc("complain", complainObj, headers)

        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("complain", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setEditCondition(false)
          setcomplainObj({
            studentID: "",
            classID: "",
            description: "",
            complainDate: "",
            status: "",
            userID: "",
          })
          setselectedStudent([])
        } else {
          setdisabled(false)
          setload(false)
          notify(message, "error")
        }
      } else {
        if (Object.keys(selectedStudent).length === 0)
          return toast.warning("please choose a Student")

        // marksObj.studentID = selectedStudent.value

        if (complainObj.complainDate == "") {
          delete complainObj.complainDate
        }
        complainObj.userID = currentUser._id
        const { data } = await postfunc("complain", complainObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          setIsOpen(false)
          notify(message, "success")
          mutate("complain", fetchAll)
          setcomplainObj({
            studentID: "",
            classID: "",
            description: "",
            complainDate: "",
            status: "",
            userID: "",
          })
          setselectedStudent([])
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
    }
  }
  const handleEdit = async transData => {
    let data = { ...transData }
    data.complainDate = moment(data.complainDate).format(
      "YYYY-MM-DD[T]HH:mm:ss"
    )
    setEditCondition(true)
    setcomplainObj(data)
    setIsOpen(true)
    setselectedStudent({
      label: data.studentID?.studentName + "-" + data.studentID?.stdID,
      value: data.studentID?._id,
    })
    await getstudentClass(data.studentID?._id)
    setcomplainObj({
      _id: data._id,
      studentID: data.studentID?._id,
      classID: data.classID?._id,
      description: data.description,
      complainDate: data.complainDate,
      status: data.status,
      // userID: data.userID,
    })
  }
  const handleDelete = data => {
    // console.log(data)
    setcomplainObj(data)
    setsweetAlertConfirm(true)
  }
  const studentIDOnachnge = async e => {
    try {
      if (e.value) {
        setselectedStudent(e)
        await getstudentClass(e.value)
        setcomplainObj({
          studentID: e.value,
          classID: "",
          description: complainObj.description,
          complainDate: complainObj.complainDate,
          status: complainObj.status,
          userID: complainObj.userID,
        })
      }
    } catch (error) {}
  }
  const getstudentClass = async classId => {
    try {
      setclassload(true)
      const { data } = await GetById(
        "certificate/studentClases",
        classId,
        headers
      )
      if (data.status) {
        if (Array.isArray(data.studentsClasses)) {
          setstudentClasses(data.studentsClasses)
        } else {
          setstudentClasses([])
        }
      } else {
        toast.error(data.message)
        setstudentClasses([])
      }
      setclassload(false)
    } catch (error) {
      toast.error(error.message)
      setclassload(false)
      setstudentClasses([])
    }
  }

  // let StudentIDOnachnge = async event => {
  //   try {
  //     setclassload(true)
  //     if (event.value) {
  //       setstudentID(event)
  //     }
  //     const { data } = await axios.get(
  //       `${url}/certificate/studentClases/${event.value}`,
  //       headers
  //     )
  //     if (data.status) {
  //       if (Array.isArray(data.studentsClasses)) {
  //         setstudentClasses(data.studentsClasses)
  //       }
  //     } else {
  //       toast.error(data.message)
  //     }
  //     setclassload(false)
  //   } catch (error) {
  //     toast.error(error.message)
  //     setclassload(false)
  //   }
  // }

  const ComplainData = {
    columns: [
      {
        label: "Student Name",
        field: "studentName",
        sort: "asc",
        width: 40,
      },
      {
        label: "Course",
        field: "courseName",
        sort: "asc",
        width: 60,
      },
      {
        label: "Class",
        field: "className",
        sort: "asc",
        width: 60,
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 60,
      },
      {
        label: "Status",
        field: "Status",
        sort: "asc",
        width: 60,
      },

      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Created User",
        field: "userName",
        sort: "asc",
        width: 270,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 50,
      },
    ],
    rows: marksData?.map(cust => {
      const data = { ...cust }
      data.userName = data.studentID?.studentName
      data.studentName =
        data.studentID?.studentName + "-" + data.studentID?.stdID
      data.className = data.classID?.className
      data.courseName = data.classID?.courseID?.courseName
      data.date = moment(data.complainDate).format("lll")
      if (data.status == "completed") {
        data.Status = (
          <div className="badge bg-success  font-size-15 badge-pill mr-2">
            {data.status}
          </div>
        )
      } else if (data.status == "process") {
        data.Status = (
          <div className="badge bg-warning font-size-15 badge-pill mr-2">
            {data.status}
          </div>
        )
      } else {
        data.Status = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.status}
          </div>
        )
      }

      data.action = (
        <div>
          <button
            onClick={() => handleEdit(cust)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
          <button
            onClick={() => handleDelete(data)}
            className="btn btn-white btn-sm mr-4"
          >
            <i className="dripicons-trash text-danger font-size-20"></i>
          </button>
          {/* <button
            type="button"
            onClick={() => handleDelete(cust)}
            className="btn btn-white btn-sm"
          >
            <i className="mdi mdi-trash-can font-size-20 text-danger "></i>
          </button> */}
        </div>
      )
      return data
    }),
  }
  const location = useLocation()
  Auhtentication(location.pathname)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Complain" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col sm={6} md={4} xl={3} className="mt-4">
              <div className="text-center"></div>
              <Modal isOpen={isOpen} toggle={tog_standard}>
                <ModalHeader
                  style={{
                    background: "#14213D",
                    color: "white",
                    border: "#0A928C",
                  }}
                  toggle={tog_standard}
                >
                  {" "}
                  <p>Complain </p>
                </ModalHeader>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={HandleSubmitBtn}
                >
                  <ModalBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Select Student</Label>
                          <Select
                            name="studentID"
                            value={selectedStudent}
                            // isClearable={true}
                            isSearchable={true}
                            onChange={e => studentIDOnachnge(e)}
                            options={students?.map((std, index) => {
                              return {
                                label: std.studentName + "-" + std.stdID,
                                value: std._id,
                                key: index,
                              }
                            })}
                          ></Select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-3 mt-2">
                          {classload ? (
                            <div>
                              <Spinner
                                className="align-center"
                                color="success"
                                animation="border"
                                role="status"
                                // style={{ width: "2rem", height: "1rem" }}
                              ></Spinner>
                            </div>
                          ) : (
                            ""
                          )}
                          <AvField
                            type="select"
                            name="classID"
                            label="Select Class"
                            value={complainObj.classID}
                            onChange={e => handleChange(e)}
                            className="form-control "
                            errorMessage="please select Class "
                            validate={{ required: { value: true } }}
                          >
                            <option vale="">Select Class</option>
                            {studentClasses?.map(clas => (
                              <option value={clas._id}>{clas.className}</option>
                            ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            name="complainDate"
                            label="Date (Optional)"
                            placeholder="Enter Date (Optional)"
                            type="datetime-local"
                            value={complainObj.complainDate}
                            onChange={e => handleChange(e)}
                            className="form-control"
                            errorMessage="Enter complainDate"
                            validate={{
                              required: { value: false },
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          {/* <AvField
                            name="status"
                            label="status"
                            placeholder="Enter status"
                            type="text"
                            value={complainObj.status}
                            onChange={e => handleChange(e)}
                            className="form-control"
                            errorMessage="Enter status"
                            validate={{
                              required: { value: true },
                            }}
                          /> */}
                          <AvField
                            type="select"
                            name="status"
                            label="Select status"
                            value={complainObj.status}
                            onChange={e => handleChange(e)}
                            className="form-control "
                            errorMessage="Please Select Status "
                            validate={{ required: { value: true } }}
                          >
                            <option vale="">Select Class</option>
                            {statusList?.map(state => (
                              <option value={state}>{state}</option>
                            ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            name="description"
                            label="description"
                            placeholder="Enter description"
                            type="textarea"
                            maxlength="255"
                            value={complainObj.description}
                            onChange={e => handleChange(e)}
                            className="form-control"
                            errorMessage="Enter description"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="danger"
                      onClick={tog_standard}
                      className="waves-effect"
                    >
                      Close
                    </Button>

                    <Button
                      type="submit"
                      style={{
                        background: "#14213D",
                        color: "white",
                      }}
                      disabled={disabled}
                      className="waves-effect waves-light"
                    >
                      {load ? "saving..." : "  Save Complain"}
                    </Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
            </Col>
          </Row>
          {sweetAlertConfirm ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={async () => {
                try {
                  // return  console.log(IncomeObj)
                  const { data } = await deletefunc(
                    "complain",
                    complainObj,
                    headers
                  )
                  const { status, message } = { ...data }
                  if (status) {
                    toast.success(message)
                    setsweetAlertConfirm(false)
                    mutate("complain", fetchAll)
                  } else {
                    toast.error(message)
                  }
                } catch (error) {
                  toast.error(error.message)
                }
              }}
              onCancel={() => setsweetAlertConfirm(false)}
            >
              make sure before you delete!
            </SweetAlert>
          ) : null}
          <ToastContainer />

          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm={6} md={10} xl={10}>
                      <h4 className="card-title">Complain </h4>
                    </Col>
                    <Col sm={6} md={2} xl={2}>
                      <Button
                        type="button"
                        onClick={tog_standard}
                        style={{ background: "#14213D", color: "white" }}
                        className="waves-effect waves-light float-end"
                      >
                        <i className="ri-add-line"></i> Add Complain
                      </Button>
                    </Col>
                  </Row>
                  {currentUser.userType != "teacher" ? (
                    <MDBDataTable
                      responsive
                      striped
                      bordered
                      data={ComplainData}
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Complain
