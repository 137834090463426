import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { postfunc } from "../../services/mainService"

const ServiceDept = () => {
  const [statementObj, setstatementObj] = useState({
    type: "",
  })
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setloading] = useState(false)
  const [ReportData, setReportData] = useState([])
  const [statementTempData, setstatementTempData] = useState([])

  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }

  //   useEffect(() => {
  //     const getReceipt = async () => {
  //       try {
  //         const { data } = await postfunc("reports/ServiceDept", {}, headers)
  //         const { status, message, ReportData } = { ...data }

  //         if (status) {
  //           ReportData(ReportData)
  //         }
  //       } catch (error) {
  //         console.log(error)
  //       }
  //     }
  //     getReceipt()
  //   }, [])

  const notify = (message, type) => toast[type](message)

  const HandleSubmitBtn = async e => {
    try {
      setloading(true)
      const { data } = await postfunc(
        "reports/ServiceDept",
        { type: statementObj.type },
        headers
      )
      const { status, message, ReportData } = { ...data }
      if (status) {
        notify(message, "success")

        setReportData(ReportData)
      } else {
        notify(message, "error")
      }

      setloading(false)
    } catch (error) {
      setloading(false)
      notify(error.message, "error")
      console.log(error.message)
    }
  }
  const handleChange = e => {
    setstatementObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const exportData = ReportData?.map(cust => {
    const data = { ...cust }
    data.date = moment(data.date).format("lll")
    data.Amount = "$" + data.amount
    data.customerName = data.customerID?.name

    data.date = moment(data.createdAt).format("lll")
    const obj = {
      serviceName: data.serviceName,
      "Customer Name": data.customerName,
      servicePrice: data.servicePrice,
      //   servicePrice: data.servicePrice,
      AmountPaid: data.AmountPaid,
      Balance: data.Balance,
      Status: data.Status,
      Date: data.date,
    }

    return obj
  })
  const data = {
    columns: [
      {
        label: "serviceName",
        field: "serviceName",
        sort: "asc",
        width: 40,
      },
      {
        label: "Customer Name",
        field: "customerName",
        sort: "asc",
        width: 200,
      },
      {
        label: "servicePrice",
        field: "servicePrice",
        sort: "asc",
        width: 60,
      },
      {
        label: "AmountPaid",
        field: "AmountPaid",
        sort: "asc",
        width: 60,
      },
      {
        label: "Balance",
        field: "Balance",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "Status",
        sort: "asc",
        width: 80,
      },
      {
        label: "Created Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
    ],
    rows: ReportData?.map(hos => {
      const data = { ...hos }
      //   console.log(data)
      data.customerName = data.customerID?.name
      data.servicePrice = "$" + data.servicePrice
      data.AmountPaid = "$" + data.AmountPaid
      data.Balance = "$" + data.Balance

      data.date = moment(data.createdAt).format("lll")

      return data
    }),
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs
              maintitle="easy soft"
              title="reports"
              breadcrumbItem="Service Report"
            />
            <ToastContainer />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={HandleSubmitBtn}
                    >
                      <Row className=" mb-3">
                        <Col md={6}>
                          <div className="mb-3">
                            <FormGroup className="form-group">
                              <AvField
                                type="select"
                                name="type"
                                label="select type"
                                value={statementObj.type}
                                onChange={e => handleChange(e)}
                                className="form-control"
                                errorMessage="please select type  "
                                validate={{ required: { value: false } }}
                              >
                                {/* <option value="">Select type</option> */}
                                <option value="1">All Dept</option>
                                {/* <option value="2">Out Of the Stock</option> */}
                              </AvField>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-3">
                            <Label>View</Label>
                            <FormGroup className="form-group">
                              <Button
                                type="submit"
                                style={{
                                  background: "#0A928C",
                                  color: "white",
                                }}
                                className=" dropdown-toggle waves-effect waves-light "
                              >
                                {loading ? "loading" : "View Report"}
                              </Button>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-3">
                            <Label>Download </Label>
                            <FormGroup className="form-group">
                              <CSVLink
                                filename={"ServiceDeptReport.csv"}
                                data={exportData}
                                className="btn btn-success"
                              >
                                Download
                              </CSVLink>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>

                    <Row>
                      <MDBDataTable responsive striped bordered data={data} />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ServiceDept
