import axios from "axios"
import { createContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import useSwr, { mutate } from "swr"
import Auhtentication from ".././authMidlleware/userMiddleWare"
import { GetAll, deletefunc, postfunc, putfunc } from "../services/mainService"
export const enrollmentContext = createContext()
enrollmentContext.displayName = "enrollmentContext"

const EnrollmentContextProvider = props => {
  const [enrollmentObj, setenrollmentObj] = useState({
    studentID: "",
    courseID: "",
    classID: "",
    Amount: "",
    enrolledDate: "",
    enrolledStatus: "",
  })
  const [coursesData, setcourses] = useState([])
  const [classData, setclassData] = useState([])
  const [classTemp, setclassTemp] = useState([])
  const [Students, setStudents] = useState([])
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [studentID, setstudentID] = useState("")

  const [collapse, setcollapse] = useState(false)
  const [Icon, setIcon] = useState({
    iconPlusOrminus: " btn-warning float-right ri-add-fill font-size-17",
  })
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const [sweetAlertConfirm, setsweetAlertConfirm] = useState(false)
  const [editID, seteditID] = useState("")
  // const [overTimeStudentsList, setoverTimeStudentsList] = useState([])
  const location = useLocation()
  Auhtentication(location.pathname)
  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT

  async function fetchAll(path) {
    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  useEffect(() => {
    const getclass = async () => {
      try {
        const { data } = await GetAll("courses", headers)
        const { data: StudentsData } = await GetAll("student", headers)
        const { data: classesData } = await GetAll("class", headers)
        // const { data: enrollmentList } = await GetAll(
        //   "Enrollment/overTime",
        //   headers
        // )

        let checkCourse = Array.isArray(data)
        let checkstudents = Array.isArray(StudentsData)
        let checkclass = Array.isArray(classesData)
        if (checkCourse) {
          setcourses(data)
        }
        if (checkclass) {
          setclassData(classesData)
          setclassTemp(classesData)
        }
        if (checkstudents) {
          setStudents(StudentsData)
        }

        // if (Array.isArray(enrollmentList.result)) {
        //   setoverTimeStudentsList(enrollmentList.result)
        // }
      } catch (error) {
        console.log(error.message)
      }
    }
    getclass()
  }, [])

  //   useSwr("branch",getMyprint);
  let { data: enrollmentlist } = useSwr("Enrollment", fetchAll)
  let { data: enrollmentOverTimeData } = useSwr("Enrollment/overTime", fetchAll)

  let enrollmentData = []
  let overTimeStudentsList = []

  let chechArray = Array.isArray(enrollmentlist)
  if (chechArray == true) {
    enrollmentData = enrollmentlist
  }
  let overTimedata =
    enrollmentOverTimeData == undefined ? [] : enrollmentOverTimeData.result

  let chechArrayoverTime = Array.isArray(overTimedata)
  if (chechArrayoverTime == true) {
    overTimeStudentsList = overTimedata
  }
  const notify = (message, type) => toast[type](message)
  const tog_standard = () => {
    setIsOpen(!isOpen)
    setenrollmentObj({
      studentID: "",
      courseID: "",
      classID: "",
      Amount: "",
      enrolledDate: "",
      enrolledStatus: "",
    })
    setEditCondition(false)
  }

  const HandleSubmitBtn = async e => {
    try {
      setdisabled(true)
      setload(true)
      if (editCondition) {
        // return  console.log("enrollmentObj",enrollmentObj)
        enrollmentObj._id = editID
        const { data } = await putfunc("Enrollment", enrollmentObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("Enrollment", fetchAll)
          mutate("Enrollment/overTime", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setenrollmentObj({
            studentID: "",
            courseID: "",
            classID: "",
            Amount: "",
            enrolledDate: "",
            enrolledStatus: "",
          })
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      } else {
        enrollmentObj.studentID = studentID.value
        // return console.log("enrollmentObj",enrollmentObj)
        const { data } = await postfunc("Enrollment", enrollmentObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("Enrollment", fetchAll)

          setenrollmentObj({
            studentID: "",
            courseID: "",
            classID: "",
            Amount: "",
            enrolledDate: "",
            enrolledStatus: "",
          })
          notify(message, "success")
          setIsOpen(false)
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
      setdisabled(false)
      setload(false)
    }
  }

  const handleChange = e => {
    setenrollmentObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const CourseONChenge = e => {
    if (e.target.value) {
      const courseClass = classTemp?.filter(
        i => i.courseID?._id == e.target.value
      )
      setclassData(courseClass)

      const courseobj = coursesData.find(item => item._id == e.target.value)

      setenrollmentObj({
        studentID: enrollmentObj.studentID,
        courseID: e.target.value,
        classID: enrollmentObj.classID,
        Amount: courseobj.coursePrice,
        enrolledDate: enrollmentObj.enrolledDate,
        enrolledStatus: enrollmentObj.enrolledStatus,
      })
    }
  }

  const StudentIDOnachnge = e => {
    try {
      console.log(e)
      if (e.value) {
        setstudentID(e)
        // setenrollmentObj({
        //   studentID: e.value,
        //   courseID: enrollmentObj.courseID,
        //   classID: enrollmentObj.classID,
        //   Amount: enrollmentObj.Amount,
        //   enrolledDate:enrollmentObj.enrolledDate,
        //   enrolledStatus: enrollmentObj.enrolledStatus,
        // })
      }
    } catch (error) {
      console.log(e)
      console.log(error)
    }
  }

  const handleEdit = async transData => {
    let data = { ...transData }
    console.log(data)
    data.courseID = data.courseID?._id
    data.teacherID = data.teacherID?._id
    data.classID = data.classID?._id
    setstudentID({
      label: data.studentID?.studentName + "-" + data.studentID.stdID,
      value: data.studentID?._id,
    })

    // data.startedDate = moment(data.startedDate).format("YYYY-MM-DD")
    // data.endDate = moment(data.endDate).format("YYYY-MM-DD")
    // data.date = new Date(data.date)
    setEditCondition(true)
    setenrollmentObj(data)
    seteditID(data?._id)
    setIsOpen(true)
    // setcollapse(true)
  }
  const handleDelete = data => {
    setenrollmentObj(data)
    setsweetAlertConfirm(true)
  }
  const initialValues = {
    studentID: "",
    courseID: "",
    classID: "",
    Amount: "",
    enrolledDate: "",
    enrolledStatus: "",

    items: [
      {
        feeName: "",
        feetype: "",
        feeAmount: 0,
      },
    ],
  }
  const handleSubmit = async (values, { resetForm }) => {
    try {
      // if (editCondition) {
      //   const { data } = await putfunc("Enrollment", values)
      //   const { status, message, info } = { ...data }
      //   if (status) {
      //     mutate("Enrollment", fetchAll)
      //     notify(message, "success")
      //     setIsOpen(false)

      //   }
      // } else {
      values.enrolledDate = new Date()
      values.enrolledStatus = "active"
      // return console.log("values",values)
      const { data } = await postfunc("Enrollment", values, headers)
      const { status, message, info } = { ...data }
      if (status) {
        mutate("Enrollment", fetchAll)

        notify(message, "success")
        setIsOpen(false)
        setcollapse(false)
        resetForm()
      } else {
        notify(message, "error")
      }
      // }
    } catch (error) {
      notify(error.message, "error")
    }
  }
  const collapseToggle = () => {
    setcollapse(!collapse)
    if (collapse) {
      setIcon({
        iconPlusOrminus: "btn-warning float-right ri-add-fill font-size-17",
      })
    } else {
      setIcon({
        iconPlusOrminus: "btn-warning float-right ri-add-fill font-size-17",
      })
    }
  }
  console.log("overTimeStudentsList", overTimeStudentsList)
  return (
    <enrollmentContext.Provider
      value={{
        handleEdit,
        handleChange,
        CourseONChenge,
        HandleSubmitBtn,
        isOpen,
        setIsOpen,
        enrollmentData,
        editCondition,
        enrollmentObj,
        tog_standard,
        handleEdit,
        tog_standard,
        coursesData,
        Students,
        classData,
        StudentIDOnachnge,
        studentID,
        editCondition,
        classTemp,
        setclassData,
        initialValues,
        handleSubmit,
        collapseToggle,
        Icon,
        setcollapse,
        collapse,
        disabled,
        load,
        deletefunc,
        setsweetAlertConfirm,
        sweetAlertConfirm,
        handleDelete,
        deletefunc,
        fetchAll,
        setEditCondition,
        overTimeStudentsList,
      }}
    >
      {props.children}
    </enrollmentContext.Provider>
  )
}

export default EnrollmentContextProvider
