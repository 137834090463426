import React, { useState,useContext } from "react"
import MetaTags from "react-meta-tags"
import {
  Col,
  Container,
  Button,
  Row,
  Card,
  CardBody,
  CardTitle,

} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import "../Dashboard/datatables.scss"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import Switch from "react-switch";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import SweetAlert from "react-bootstrap-sweetalert";
import useSwr,{mutate,trigger,useSWRConfig} from "swr"
import {
  blockUsers,
  fetchAll
} from "../../services/mainService"
import {usersContext} from "../../context/userContext"
const UserTable = () => {
    const { 
        collapse,
        usersObj,
        isOpen,
        editCondition,
        tog_standard,
        HandleSubmitBtn,
        handleChange,
        handleEdit,
        handleCheckBoxChange,
        collapseToggle,
        btnCancelTogle,
        userPermessions,
        Users ,
        setuserImage,
        userImage,
        setisFits,
        isFits,
        setusersObj,
        handleBlock,
        OnSymbol,
        Offsymbol,
        SweetAlertState,
        setSweetAlertState,
        userBlockData,
     

    } =
      useContext(usersContext);

    const data = {
        columns: [
          // {
          //   label: "image",
          //   field: "img",
          //   sort: "asc",
          //   width: 150,
          // },
          {
            label: "Name",
            field: "name",
            sort: "asc",
            width: 150,
          },
          {
            label: "phone",
            field: "phone",
            sort: "asc",
            width: 270,
          },
          {
            label: "User Name",
            field: "username",
            sort: "asc",
            width: 200,
          },
          {
            label: "User Type",
            field: "userType",
            sort: "asc",
            width: 100,
          },
          {
            label: "Date",
            field: "date",
            sort: "asc",
            width: 150,
          },
          {
            label: "Status",
            field: "Block",
            sort: "asc",
            width: 150,
          },
    
          {
            label: "Action",
            field: "action",
            sort: "asc",
            width: 100,
          },
        ],
        rows: Users?.map(hos => {
          const data = { ...hos }
          data.date = moment(data.createdAt).format("lll")
          // data.image = new Buffer.from(data.coverImage.data).toString("base64")
          // data.image = new Buffer.from(data.userImage.data).toString("base64")
    
          // data.img = (
          //   <img
          //     src={`data:image/png;base64,${data.image}`}
          //     // className="avatar-sm rounded-triangle avatar-xs"
          //     onClick={() => {
          //       setisFits(true)
          //       setuserImage(`data:image/png;base64,${data.image}`)
          //     }}
          //     alt=""
          //     width="75"
          //   />
    
          //   // <img
    
          //   //   src={`data:image/png;base64,${data.image}`}
          //   //   alt=""
          //   //   className="avatar-sm rounded-triangle avatar-xs img-fluid"
          //   // />
          // )
     data.Block = (
        <div>
          <Switch
            uncheckedIcon={<Offsymbol />}
            checkedIcon={<OnSymbol />}
            className="me-1 mb-sm-8 mb-2"
            onColor="#28A745"
            offColor="#E63336"
            onChange={() => {
              handleBlock(data);
              // setswitch1(!switch1);
            }}
            checked={data.status}
          ></Switch>
        </div>
      
      );
          data.action = (
            <div>
              <button
                onClick={() => handleEdit(hos)}
                type="button"
                className="btn btn-white btn-sm mr-4"
              >
                <i
                  style={{
                    color: "#14213D",
                  }}
                  className="mdi mdi-pencil font-size-20 "
                ></i>
              </button>
            </div>
          )
          return data
        }),
      }
       return (
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="align-items-center  mb-3">
                      <Col md={8}>
                        <CardTitle className="h4">users Table </CardTitle>
                      </Col>

                      <Col md="4">
                        
                      </Col>
                    </Row>

                    <MDBDataTable responsive striped bordered data={data} />
                  </CardBody>
                </Card>
              </Col>
              <ToastContainer/>
              {SweetAlertState.deleted ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={async () => {
                // const token = localStorage.getItem("token");
                let obj={
                  _id:userBlockData._id,
                  currentstatus:userBlockData.currentstatus
                }
             
                const { data } = await blockUsers("users", obj);
                // return  console.log("data",data)

                const { status, message, info } = { ...data };
                if (status) {
                  mutate("users",fetchAll)
                  
                  toast.success(message);
                  setSweetAlertState({
                    id: null,
                    state: false,
                    deleted: false,
                  });
                } else {
                  toast.warning(message);
                }
              }}
              onCancel={() => {
                setSweetAlertState({ id: null, state: false, deleted: false });
              }}
            >
              You are blocking this user?
            </SweetAlert>
          ) : null}
            </Row>
            
       
  )
}

export default UserTable
