import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import "../Dashboard/datatables.scss"
import ExpenseTable from "./expenseTable"
import ExpenseForm from "./expenseForm"
import moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ExpenseContextProvider from "../../context/expenseContext"

const Income = () => {
  return (
    <React.Fragment>
      <ExpenseContextProvider>
        <div className="page-content">
          <MetaTags>
            <title>software | Expense</title>
          </MetaTags>
          <Container fluid>
            <div className="page-title-box">
              <Breadcrumbs
                maintitle="Software"
                title="Transections"
                breadcrumbItem="Expense"
              />
              <ExpenseForm />
              <ExpenseTable />
            </div>
          </Container>
        </div>
      </ExpenseContextProvider>
    </React.Fragment>
  )
}

export default Income
