import React, { createContext, useState, useEffect } from "react"
import moment from "moment"
import {
    GetAll,
    fetchAll,
    postfunc,
    putfunc,
} from "../services/mainService"
import useSwr,{mutate,trigger,useSWRConfig} from "swr"
import { ToastContainer, toast } from "react-toastify";
import axios from "axios"
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom"
import Auhtentication from "../authMidlleware/userMiddleWare"

export const subscriptionContext = createContext()
subscriptionContext.displayName = "subscriptionContext"

const SubscriptionContextProvider = props => {
    const [susbcriptionObj, setsusbcriptionObj] = useState({
        subscriptoinTerm:"",
        amountPaid:"",
        PhonePaid:"",
        accountType:"",
        datePaid:"",
        expiredDate:""
      })
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [disabled, setdisabled] = useState(false)
const [load, setload] = useState(false)
const location = useLocation();
// Auhtentication(location.pathname)
//   useSwr("branch",getMyprint);

const token = localStorage.getItem("token");
const headers = {
  headers: {
    "Content-Type": "application/json",
    "auth-token": token
  },
};
let epiEndPoint=process.env.REACT_APP_ENDPOINT
const epiEndPointDecoration = process.env.REACT_APP_DECORATION_ENDPOINT

async function fetchAll(path) {
  let brandType = sessionStorage.getItem("brandType")
    if (brandType == "decoration") {
      epiEndPoint = epiEndPointDecoration
    }
  const {data}=await axios.get(epiEndPoint+"/"+path,headers);
  return data
};

let { data:subscriptionList } = useSwr("subscription",fetchAll)

let subscriptionData=[]
let chechArray=Array.isArray(subscriptionList)
if(chechArray==true){
  subscriptionData=subscriptionList
}
  const notify = (message, type) => toast[type](message);
  const tog_standard = () => {
    setIsOpen(!isOpen)
    setsusbcriptionObj({
      subscriptoinTerm:"",
      amountPaid:"",
      PhonePaid:"",
      accountType:"",
      datePaid:"",
      expiredDate:""
    })
    setEditCondition(false)
  }

  const HandleSubmitBtn = async e => {
    try {
      setdisabled(true)
      setload(true)
      if (editCondition) {

        const {data} = await putfunc("subscription",susbcriptionObj,headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("subscription", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setsusbcriptionObj({
            subscriptoinTerm:"",
            amountPaid:"",
            PhonePaid:"",
            accountType:"",
            datePaid:"",
            expiredDate:""
          })
        }else{
          notify(message, "error");
          setdisabled(false)
          setload(false)
        }
      } else {
        // notify("welcome", "error");
        // return console.log("susbcriptionObj", susbcriptionObj)


        const {data} = await postfunc("subscription",susbcriptionObj,headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("subscription", fetchAll)
          setsusbcriptionObj({
            subscriptoinTerm:"",
            amountPaid:"",
            PhonePaid:"",
            accountType:"",
            datePaid:"",
            expiredDate:""
          })
          notify(message, "success")
          setIsOpen(false)
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
      setdisabled(false)
          setload(false)
    }
  }

  const handleChange = e => {
    setsusbcriptionObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const handleEdit = async (transData) => {
    let data = { ...transData };
    data.expiredDate = moment(data.expiredDate).format("YYYY-MM-DD[T]HH:mm:ss");
    data.datePaid = moment(data.datePaid).format("YYYY-MM-DD[T]HH:mm:ss");

    setEditCondition(true);
    setsusbcriptionObj(data);
    setIsOpen(true);
  };

  return (
    <subscriptionContext.Provider
      value={{
        handleEdit,
        handleChange,
        HandleSubmitBtn,
        tog_standard,
        isOpen,
        setIsOpen,
        subscriptionData,
        editCondition,
        susbcriptionObj,
        disabled,
        load
      }}
    >
      {props.children}
    </subscriptionContext.Provider>
  );


}

export default SubscriptionContextProvider
