import axios from "axios"
import jwtDecode from "jwt-decode"
import moment from "moment"
import { createContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import useSwr, { mutate } from "swr"
import { postfunc, putfunc } from "../services/mainService"

import Auhtentication from ".././authMidlleware/userMiddleWare"
export const EmployeeContext = createContext()
EmployeeContext.displayName = "EmployeeContext"

const EmployeeContextProvider = props => {
  const [employeeObj, setemployeeObj] = useState({
    name: "",
    phone: "",
    gender: "",
    address: "",
    educationalLevel: "",
    departmentWork: "",
    jobTitle: "",
    bloodType: "",
    typeOfworking: "",
    userID: "",
    dateOfBirth: "",
    salaryAmount: "",
    totalTaskRequired: "",
    commissionRate: "0",
  })
  const [EmployeeData, setEmployeeData] = useState([])
  const [editCondition, setEditCondition] = useState(false)
  const [currentUser, setcurrentUser] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)

  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT
  const epiEndPointDecoration = process.env.REACT_APP_DECORATION_ENDPOINT

  async function fetchAll(path) {
    let brandType = sessionStorage.getItem("brandType")
    if (brandType == "decoration") {
      epiEndPoint = epiEndPointDecoration
    }

    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }
  useEffect(() => {
    const handleCustomers = async () => {
      try {
        const token = localStorage.getItem("token")
        const user = jwtDecode(token)
        setcurrentUser(user)
      } catch (error) {}
    }
    handleCustomers()
  }, [])

  const location = useLocation()

  Auhtentication(location.pathname)
  let { data: employeelist } = useSwr("employee", fetchAll)
  let employee = []
  let chechArray = Array.isArray(employeelist)

  if (chechArray == true) {
    employee = employeelist
  }
  const notify = (message, type) => toast[type](message)
  const tog_standard = () => {
    setIsOpen(!isOpen)
  }

  const HandleSubmitBtn = async e => {
    try {
      setdisabled(true)
      setload(true)
      if (employeeObj.totalTaskRequired == "") {
        delete employeeObj.totalTaskRequired
      }
      if (editCondition) {
        employeeObj.employeeStatus = "active"
        //  return console.log(employeeObj)
        const { data } = await putfunc("employee", employeeObj, headers)

        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("employee", fetchAll)
          toast.success(message)
          setIsOpen(false)
          setemployeeObj({
            name: "",
            phone: "",
            gender: "",
            address: "",
            educationalLevel: "",
            departmentWork: "",
            jobTitle: "",
            bloodType: "",
            typeOfworking: "",
            employeeStatus: "",
            userID: "",
            dateOfBirth: "",
          })
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      } else {
        employeeObj.userID = currentUser._id

        // return console.log(employeeObj)
        const { data } = await postfunc("employee", employeeObj, headers)

        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("employee", fetchAll)
          setemployeeObj({
            name: "",
            phone: "",
            gender: "",
            address: "",
            educationalLevel: "",
            departmentWork: "",
            jobTitle: "",
            bloodType: "",
            typeOfworking: "",
            employeeStatus: "",
            userID: "",
            dateOfBirth: "",
            commissionRate: "",
          })
          toast.success(message)
          setIsOpen(false)
        } else {
          toast.error(message)
          setdisabled(false)
          setload(false)
        }
      }
    } catch (error) {
      console.log(error.response)
      setdisabled(false)
      setload(false)
      notify(error.message, "error")
    }
  }

  const handleChange = e => {
    setemployeeObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const handleEdit = async transData => {
    let data = { ...transData }
    data.dateOfBirth = moment(data.dateOfBirth).format("YYYY-MM-DD[T]HH:mm:ss")
    setEditCondition(true)
    setemployeeObj(data)
    setIsOpen(true)
  }

  return (
    <EmployeeContext.Provider
      value={{
        handleEdit,
        handleChange,
        HandleSubmitBtn,
        isOpen,
        EmployeeData,
        editCondition,
        tog_standard,
        handleEdit,
        tog_standard,
        employee,
        employeeObj,
        disabled,
        load,
      }}
    >
      {props.children}
    </EmployeeContext.Provider>
  )
}

export default EmployeeContextProvider
