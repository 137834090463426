import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import "../Dashboard/datatables.scss"
import IncomeTable from "./incomeTable"
import IncomeForm from "./incomeForm"
import moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import IncomeContextProvider from "../../context/incomeContext"

const Income = () => {
  return (
    <React.Fragment>
      <IncomeContextProvider>
        <div className="page-content">
          <MetaTags>
            <title> software | Income </title>
          </MetaTags>
          <Container fluid>
            <div className="page-title-box">
              <Breadcrumbs
                maintitle="Software"
                title="Transections"
                breadcrumbItem="Income"
              />
              <IncomeForm />
              <IncomeTable />
            </div>
          </Container>
        </div>
      </IncomeContextProvider>
    </React.Fragment>
  )
}

export default Income
