import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { postfunc } from "../../services/mainService"

const ServiceReport = () => {
  const [statementObj, setstatementObj] = useState({
    startDate: "",
    endDate: "",
  })
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setloading] = useState(false)
  const [ReportData, setReportData] = useState([])
  const [statementTempData, setstatementTempData] = useState([])

  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }

  useEffect(() => {
    const getReceipt = async () => {
      try {
        // const { data: receiptList } = await GetAll("transection", headers)
        // let checkTransection = Array.isArray(receiptList)
        // if (checkTransection) {
        //   let balalance = 0
        //   let statementData = receiptList?.map(item => {
        //     let obj = JSON.parse(JSON.stringify(item))
        //     obj.transectionType == "income"
        //       ? (balalance += obj.amount)
        //       : (balalance -= obj.amount)
        //     obj.runningBalance = balalance
        //     //   let newObject = {...obj, ...obj.customerID };
        //     return obj
        //   })
        // setstatementTempData(statementData)
        // ReportData(statementData)
        // }
      } catch (error) {
        console.log(error.message)
      }
    }
    getReceipt()
  }, [])

  const notify = (message, type) => toast[type](message)

  const HandleSubmitBtn = async e => {
    try {
      let start = moment(statementObj.startDate).format("YYYY-MM-DD")
      let end = moment(statementObj.endDate).format("YYYY-MM-DD")
      setloading(true)
      const { data } = await postfunc(
        "reports/serviceReport",
        statementObj,
        headers
      )
      const { status, message, ReportData } = { ...data }
      if (status) {
        notify(message, "success")
        // let result = ReportData?.filter(item => {
        //   let date = moment(item.createdAt).format("YYYY-MM-DD")
        //   return date >= start && date <= end
        // })
        setReportData(ReportData)
      } else {
        notify(message, "error")
      }

      setloading(false)
    } catch (error) {
      setloading(false)
      notify(error.message, "error")
      console.log(error.message)
    }
  }
  const handleChange = e => {
    setstatementObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const exportData = ReportData?.map(cust => {
    const data = { ...cust }
    data.date = moment(data.date).format("lll")
    data.Amount = "$" + data.amount
    data.customerName = data.customerID?.name

    data.date = moment(data.createdAt).format("lll")
    const obj = {
      serviceName: data.serviceName,
      "Customer Name": data.customerName,
      servicePrice: data.servicePrice,
      AmountPaid: data.AmountPaid,
      Balance: data.Balance,
      Status: data.Status,
      Date: data.date,
    }

    return obj
  })
  const data = {
    columns: [
      {
        label: "serviceName",
        field: "serviceName",
        sort: "asc",
        width: 40,
      },
      {
        label: "Customer Name",
        field: "customerName",
        sort: "asc",
        width: 200,
      },
      {
        label: "servicePrice",
        field: "servicePrice",
        sort: "asc",
        width: 60,
      },
      {
        label: "AmountPaid",
        field: "AmountPaid",
        sort: "asc",
        width: 60,
      },
      {
        label: "Balance",
        field: "Balance",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "Status",
        sort: "asc",
        width: 80,
      },
      {
        label: "Created Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
    ],
    rows: ReportData?.map(hos => {
      const data = { ...hos }
      //   console.log(data)
      data.customerName = data.customerID?.name
      data.servicePrice = "$" + data.servicePrice
      data.AmountPaid = "$" + data.AmountPaid
      data.Balance = "$" + data.Balance

      data.date = moment(data.createdAt).format("lll")

      return data
    }),
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs
              maintitle="easy soft"
              title="reports"
              breadcrumbItem="Service Report"
            />
            <ToastContainer />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={HandleSubmitBtn}
                    >
                      <Row className=" mb-3">
                        <Col md={4}>
                          <div className="mb-3">
                            <FormGroup className="form-group">
                              <AvField
                                type="date"
                                name="startDate"
                                label="date started"
                                value={statementObj.startDate}
                                onChange={e => handleChange(e)}
                                className="form-control"
                                errorMessage="please select start date"
                                validate={{ required: { value: true } }}
                              ></AvField>
                            </FormGroup>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="mb-3">
                            <FormGroup className="form-group">
                              <AvField
                                type="date"
                                name="endDate"
                                label=" Ended Date "
                                value={statementObj.endDate}
                                onChange={e => handleChange(e)}
                                className="form-control"
                                errorMessage="please end Date "
                                validate={{ required: { value: true } }}
                              ></AvField>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-3">
                            <Label>View</Label>
                            <FormGroup className="form-group">
                              <Button
                                type="submit"
                                style={{
                                  background: "#0A928C",
                                  color: "white",
                                }}
                                className=" dropdown-toggle waves-effect waves-light "
                              >
                                {/* <i className="ion ion-md-add me-2"></i> */}
                                {loading ? "loading" : "View Report"}
                              </Button>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-3">
                            <Label>Download </Label>
                            <FormGroup className="form-group">
                              <CSVLink
                                filename={"ServiceReport.csv"}
                                data={exportData}
                                className="btn btn-success"
                              >
                                Download
                              </CSVLink>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>

                    <Row>
                      <MDBDataTable responsive striped bordered data={data} />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ServiceReport
