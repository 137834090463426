import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { MDBDataTable } from "mdbreact"
// import axios from "axios"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  CardHeader,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
} from "reactstrap"
import moment from "moment"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"
// import { FetchTrensections } from "./TransectionApi";
import Datepicker from "react-datepicker"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import axios from "axios"
import jwtDecode from "jwt-decode"
import useSwr, { mutate, trigger, useSWRConfig } from "swr"
import Select from "react-select"
import { useLocation } from "react-router-dom"
import Auhtentication from "../../authMidlleware/userMiddleWare"
import {
  GetAll,
  fetchAll,
  postfunc,
  putfunc,
  deletefunc,
} from "../../services/mainService"
const Marks = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [editCondition, setEditCondition] = useState(false)
  const [marksObj, setmarksObj] = useState({
    studentID: "",
    classID: "",
    subjectID: "",
    Amount: "",
  })
  const [sweetAlertConfirm, setsweetAlertConfirm] = useState(false)

  const [Subjects, setSubjects] = useState([])
  const [subjectTemp, setsubjectTemp] = useState([])
  const [selectedStudent, setselectedStudent] = useState({})

  const [students, setstudents] = useState([])

  const [classData, setclassData] = useState([])
  const [classTempData, setclassTempData] = useState([])

  const [currentUser, setcurrentUser] = useState({})
  const [stdenrollemntData, setstdenrollemntData] = useState([])
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)

  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT

  async function fetchAll(path) {
    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  useEffect(() => {
    async function onload() {
      const { data: enrollment } = await GetAll("enrollment", headers)
      const { data: Subjectslist } = await GetAll("subject", headers)
      const { data: classslist } = await GetAll("class", headers)
      const { data: studentlist } = await GetAll("student", headers)
      let checkenrollment = Array.isArray(enrollment)
      let checksubject = Array.isArray(Subjectslist)
      let checkclass = Array.isArray(classslist)
      let checkstudent = Array.isArray(studentlist)
      if (checksubject) {
        setSubjects(Subjectslist)
        setsubjectTemp(Subjectslist)
      }
      if (checkstudent) {
        setstudents(studentlist)
      }
      if (checkenrollment) {
        setstdenrollemntData(enrollment)
      }
      if (checkclass) {
        // console.log("classslist",classslist)
        setclassTempData(classslist)
        setclassData(classslist)
      }
      const token = localStorage.getItem("token")
      if (token) {
        const user = jwtDecode(token)
        setcurrentUser(user)
      }
    }
    onload()
  }, [])

  let marksData = []
  const { data: marksList } = useSwr("marks", fetchAll)
  // console.log("marksList",marksList)
  let chechArray = Array.isArray(marksList)
  if (chechArray == true) {
    marksData = marksList
  }

  const tog_standard = () => {
    setIsOpen(!isOpen)
    setmarksObj({
      serviceName: "",
      servicePrice: "",
      customerID: "",
    })
    setselectedStudent({})
    setEditCondition(false)
  }
  const breadcrumbItems = [
    { title: "minibank", link: "#" },
    { title: "customer", link: "#" },
  ]
  const handleChange = e => {
    setmarksObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const notify = (message, type) => toast[type](message)

  const HandleSubmitBtn = async e => {
    try {
      e.preventDefault()
      const token = localStorage.getItem("token")
      setdisabled(true)
      setload(true)
      if (editCondition) {
        const { data } = await putfunc("marks", marksObj, headers)
        console.log(data.info)
        marksObj.studentID = selectedStudent.value
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("marks", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setEditCondition(false)
        } else {
          setdisabled(false)
          setload(false)
          notify(message, "error")
        }
      } else {
        if (Object.keys(selectedStudent).length === 0)
          return toast.warning("please choose a Student")

        marksObj.studentID = selectedStudent.value
        // marksObj.userID = currentUser._id
        //   return   console.log("marksObj",marksObj);
        const { data } = await postfunc("marks", marksObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          //   setIncome((prev) => {
          //     return [...prev, info];
          //   });
          setdisabled(false)
          setload(false)
          setIsOpen(false)

          notify(message, "success")
          mutate("marks", fetchAll)
          setmarksObj({
            date: new Date(),
            description: "",
            amount: "",
          })
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
    }
  }
  const handleEdit = async transData => {
    let data = { ...transData }
    console.log(data)
    setEditCondition(true)
    setmarksObj({
      // studentID: data.studentID?._id,
      classID: data.classID?._id,
      subjectID: data.subjectID?._id,
      Amount: data.Amount,
    })
    setIsOpen(true)
    setselectedStudent({
      label: data.studentID?.studentName + "-" + data.studentID?.stdID,
      value: data.studentID?._id,
    })
  }
  const handleDelete = data => {
    // console.log(data)
    setmarksObj(data)
    setsweetAlertConfirm(true)
  }
  const studentIDOnachnge = e => {
    try {
      console.log(e)
      if (e.value) {
        setselectedStudent(e)

        let studentClass = stdenrollemntData.filter(
          clas => clas.studentID?._id === e.value
        )

        let filtered = []
        classTempData.filter(function (newData) {
          return studentClass.filter(function (oldData) {
            if (newData._id == oldData.classID?._id) {
              filtered.push(newData)
            }
          })
        })
        setclassData(filtered)
        setSubjects([])
        setmarksObj({
          studentID: e.value,
          classID: marksObj.classID,
          subjectID: marksObj.subjectID,
          Amount: marksObj.Amount,
        })
      }
    } catch (error) {
      console.log(e)
      console.log(error)
    }
  }
  const classIDOnachnge = e => {
    try {
      if (e.target.value) {
        let currentclass = classTempData.find(
          clas => clas._id == e.target.value
        )

        let classSubject = subjectTemp.filter(
          clas => clas.courseID?._id == currentclass.courseID?._id
        )
        setSubjects(classSubject)
        setmarksObj({
          studentID: marksObj.studentID,
          classID: e.target.value,
          subjectID: marksObj.subjectID,
          Amount: marksObj.Amount,
        })
      }
    } catch (error) {
      console.log(e)
      console.log(error)
    }
  }

  const marks = {
    columns: [
      {
        label: "studentName",
        field: "studentName",
        sort: "asc",
        width: 40,
      },
      {
        label: "className",
        field: "className",
        sort: "asc",
        width: 200,
      },
      {
        label: "subjectName",
        field: "subjectName",
        sort: "asc",
        width: 60,
      },
      {
        label: "marks",
        field: "Amount",
        sort: "asc",
        width: 60,
      },

      {
        label: "Created Date",
        field: "date",
        sort: "asc",
        width: 270,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 50,
      },
    ],
    rows: marksData?.map(cust => {
      const data = { ...cust }
      // data.studentName = data.studentID?.studentName
      data.studentName =
        data.studentID?.studentName + " -" + data.studentID?.stdID
      data.className = data.classID?.className
      data.subjectName = data.subjectID?.subjectName

      data.date = moment(data.createdAt).format("lll")
      if (data.Status == "percialPaid") {
        data.Status = (
          <div className="badge bg-info  font-size-15 badge-pill mr-2">
            {data.Status}
          </div>
        )
      } else if (data.Status == "fullPaid") {
        data.Status = (
          <div className="badge bg-success font-size-15 badge-pill mr-2">
            {data.Status}
          </div>
        )
      } else {
        data.Status = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.Status}
          </div>
        )
      }

      data.action = (
        <div>
          <button
            onClick={() => handleEdit(cust)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
          <button
            onClick={() => handleDelete(data)}
            className="btn btn-white btn-sm mr-4"
          >
            <i className="dripicons-trash text-danger font-size-20"></i>
          </button>
          {/* <button
            type="button"
            onClick={() => handleDelete(cust)}
            className="btn btn-white btn-sm"
          >
            <i className="mdi mdi-trash-can font-size-20 text-danger "></i>
          </button> */}
        </div>
      )
      return data
    }),
  }
  const location = useLocation()
  Auhtentication(location.pathname)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Subjects" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col sm={6} md={4} xl={3} className="mt-4">
              <div className="text-center"></div>
              <Modal isOpen={isOpen} toggle={tog_standard}>
                <ModalHeader
                  style={{
                    background: "#14213D",
                    color: "white",
                    border: "#0A928C",
                  }}
                  toggle={tog_standard}
                >
                  {" "}
                  <p>Register Results </p>
                </ModalHeader>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={HandleSubmitBtn}
                >
                  <ModalBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Select Student</Label>
                          <Select
                            name="studentID"
                            value={selectedStudent}
                            // isClearable={true}
                            isSearchable={true}
                            onChange={e => studentIDOnachnge(e)}
                            options={students?.map((std, index) => {
                              return {
                                label: std.studentName + "-" + std.stdID,
                                value: std._id,
                                key: index,
                              }
                            })}
                          ></Select>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            name="classID"
                            label="Select class"
                            placeholder="Enter class ...."
                            type="select"
                            value={marksObj.classID}
                            onChange={e => classIDOnachnge(e)}
                            className="form-control"
                            errorMessage="Enter class"
                            validate={{
                              required: { value: true },
                            }}
                          >
                            <option value="">select class</option>
                            {classData?.map(clas => {
                              return (
                                <option value={clas._id}>
                                  {clas.className}
                                </option>
                              )
                            })}
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <AvField
                            name="subjectID"
                            label="Select subject"
                            placeholder="Enter subject ...."
                            type="select"
                            value={marksObj.subjectID}
                            onChange={e => handleChange(e)}
                            className="form-control"
                            errorMessage="Enter subject"
                            validate={{
                              required: { value: true },
                            }}
                          >
                            <option value="">select subject</option>
                            {Subjects?.map(subj => {
                              return (
                                <option value={subj._id}>
                                  {subj.subjectName}
                                </option>
                              )
                            })}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            name="Amount"
                            label="Marks"
                            placeholder="Enter marks like 80 ...."
                            type="text"
                            value={marksObj.Amount}
                            onChange={e => handleChange(e)}
                            className="form-control"
                            errorMessage="Enter marks"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="danger"
                      onClick={tog_standard}
                      className="waves-effect"
                    >
                      Close
                    </Button>

                    <Button
                      type="submit"
                      style={{
                        background: "#14213D",
                        color: "white",
                      }}
                      disabled={disabled}
                      className="waves-effect waves-light"
                    >
                      {load ? "saving..." : "  Save Result"}
                    </Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
            </Col>
          </Row>
          {sweetAlertConfirm ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={async () => {
                try {
                  // return  console.log(IncomeObj)
                  const { data } = await deletefunc("marks", marksObj, headers)
                  const { status, message } = { ...data }
                  if (status) {
                    toast.success(message)
                    setsweetAlertConfirm(false)
                    mutate("marks", fetchAll)
                  } else {
                    toast.error(message)
                  }
                } catch (error) {
                  toast.error(error.message)
                }
              }}
              onCancel={() => setsweetAlertConfirm(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
          <ToastContainer />

          {/* data table Row  */}
          <Row>
            <Col xs={12}>
              <Card>
                {/* <CardHeader  style={{background:"#14213D",color:"white"}}>
                 Here you can register your customer and also get's Subjects record.
                </CardHeader> */}
                <CardBody>
                  <Row className="mb-2">
                    <Col sm={6} md={8} xl={10}>
                      <h4 className="card-title">Results Table </h4>
                    </Col>
                    <Col sm={6} md={4} xl={2}>
                      <Button
                        type="button"
                        onClick={tog_standard}
                        style={{ background: "#14213D", color: "white" }}
                        className="waves-effect waves-light float-right"
                      >
                        <i className="ri-add-line"></i> Add Result
                      </Button>
                    </Col>
                  </Row>

                  <MDBDataTable responsive striped bordered data={marks} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Marks
