import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import {Container} from "reactstrap"
import "../Dashboard/datatables.scss"
import ReceiptTable from "./receiptTable"
import ReceiptForm from "./receiptForm"
import moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReceiptContextProvider from "../../context/receiptContext"

const Receipt = () => {
  return (
    <React.Fragment>
      <ReceiptContextProvider>
      <div className="page-content">
        <MetaTags>
          <title>
         Receipt | easy soft
          </title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs maintitle="Veltrix" title="Receipt" breadcrumbItem="receipt" />
             <ReceiptForm/>
              <ReceiptTable/>
          </div>
        </Container>
      </div>
      </ReceiptContextProvider>
    </React.Fragment>
  )
}

export default Receipt
