import { AvField, AvForm } from "availity-reactstrap-validation"
import axios from "axios"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table,
} from "reactstrap"
import useSWR, { mutate } from "swr"
import headerImage from "../../assets/images/softwarelogo.png"
// import "./datatables.scss"
import { MDBDataTable } from "mdbreact"
import { ToastContainer, toast } from "react-toastify"
// import { ToastContainer, toast } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"
import jwtDecode from "jwt-decode"
import { useReactToPrint } from "react-to-print"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import { GetAll, postfunc } from "../../services/mainService"

import { useLocation } from "react-router-dom"
import Auhtentication from "../../authMidlleware/userMiddleWare"
const SalesReceipts = () => {
  const [isopen, setisopen] = useState(false)
  const [updateopen, setupdateopen] = useState(false)
  const [viewData, setviewData] = useState({})
  const [showModel, setshowModel] = useState(false)
  const [loading, setloading] = useState(false)
  const [receiptObj, setReceiptObj] = useState({
    customerID: "",
    userID: "",
    invoiceID: "",

    totalAmount: 0,
    amountPaid: 0,
    phoneReceipt: "0613661161",
    phonePaid: "",
    datePaid: "",
    balnce: 0,
  })
  const token = sessionStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  const location = useLocation()
  Auhtentication(location.pathname)

  let url = process.env.REACT_APP_DECORATION_ENDPOINT

  let handleView = data => {
    console.log(data)
    setviewData(data)
    setshowModel(true)
  }
  let togle = () => {
    setshowModel(!showModel)
  }
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "prescription",
  })

  const tog_standard = () => {
    setisopen(!isopen)
  }
  const update_tog = () => {
    setupdateopen(!updateopen)
  }

  // get data
  async function fetchr() {
    const { data } = await axios.get(`${url}/receipt`, headers)
    return data
  }

  let ticket = []

  const { data: tiketdata, error } = useSWR(`${url}/tekit`, fetchr)
  if (Array.isArray(tiketdata)) {
    ticket = tiketdata
  }
  // tiket post
  const handleSubmit = async (event, value) => {
    // console.log(value)
    try {
      if (receiptObj.amountPaid > receiptObj.totalAmount) {
        return toast.error(
          `amount paid must less then or equal to ${receiptObj.totalAmount}`
        )
      }
      delete receiptObj.totalAmount
      delete receiptObj.balance
      receiptObj.userID = currentUser.id
      //  console.log("currentUser",currentUser)

      //  return console.log("receiptObj",receiptObj)
      setloading(true)
      const { data } = await postfunc(`receipt`, receiptObj, headers)
      if (data.status) {
        mutate(`${url}/receipt`, fetchr)
        toast.success(data.message)
        setisopen(false)
        setReceiptObj({
          customerID: "",
          userID: "",
          invoiceID: "",

          totalAmount: 0,
          amountPaid: 0,
          phoneReceipt: "0614886697",
          phonePaid: "",
          datePaid: "",
          balnce: 0,
        })
      } else {
        toast.success(data.message)
      }
      setloading(false)
    } catch (error) {
      console.log(error.message)
    }
  }
  // put or update
  const [updateid, setupdateid] = useState([])
  const [updatedata, setupdatedata] = useState([])
  const doctorupdatData = updatadr => {
    update_tog()
    setupdateid(updatadr._id)
    setupdatedata(updatadr)
  }

  const [deletedr, setdeletedr] = useState([])
  const [deletealert, setdeletealert] = useState(false)

  const handledoctordelete = deletedata => {
    setdeletedr(deletedata._id)
    setdeletealert(true)
  }
  const handledeletedoctor = async () => {
    // console.log("delte")
    try {
      const { data } = await axios.delete(`${url}/${deletedr}`, headers)
      setdeletealert(false)
      if (data.status === "success") {
        // toast.success(data.message)

        mutate(url, fetchr)
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  //get data pataint
  const [getdoctordata, setgetdoctordata] = useState([])
  const [invoiceData, setinvoiceData] = useState([])
  const [invoiceTemp, setinvoiceTemp] = useState([])
  const [patientData, setPatientData] = useState([])

  const [currentUser, setcurrentUser] = useState({})
  //   console.log(getdoctordata)
  useEffect(() => {
    async function onload() {
      try {
        const { data: patientData } = await GetAll(`customers`, headers)

        if (Array.isArray(patientData)) {
          setPatientData(patientData)
        }

        const { data } = await GetAll(`invoice`, headers)
        if (Array.isArray(data)) {
          setinvoiceTemp(data)
          // setinvoiceData(data)
        }
        const token = sessionStorage.getItem("token")
        if (token) {
          const user = jwtDecode(token)
          setcurrentUser(user)
        }
      } catch (error) {
        console.log(error.message)
      }
    }
    onload()
  }, [])

  const PatientOnChange = event => {
    try {
      let patientInvoices = invoiceTemp.filter(
        invoice => invoice.customerID._id == event.target.value
      )
      setinvoiceData(patientInvoices)
      // return console.log("patientInvoices",patientInvoices)

      setReceiptObj({
        customerID: event.target.value,
        invoiceID: receiptObj.invoiceID,
        totalAmount: 0,
        phonePaid: receiptObj.phonePaid,
        phoneReceipt: receiptObj.phoneReceipt,
        amountPaid: receiptObj.amountPaid,
        datePaid: receiptObj.datePaid,
      })
    } catch (error) {
      console.log(error.message)
    }
  }

  const InvoiceOnchange = event => {
    let currentInvoice = invoiceData.find(
      invoice => invoice._id === event.target.value
    )
    // console.log("currentInvoice",currentInvoice)
    // return
    setReceiptObj({
      customerID: receiptObj.customerID,
      invoiceID: event.target.value,
      totalAmount: currentInvoice.balance ? currentInvoice.balance : 0,
      phonePaid: receiptObj.phonePaid,
      phoneReceipt: receiptObj.phoneReceipt,
      amountPaid: receiptObj.amountPaid,
      datePaid: receiptObj.datePaid,
    })
  }

  const AmountPaidOnChange = event => {
    setReceiptObj({
      customerID: receiptObj.customerID,
      invoiceID: receiptObj.invoiceID,
      totalAmount: receiptObj.totalAmount,
      phonePaid: receiptObj.phonePaid,
      phoneReceipt: receiptObj.phoneReceipt,
      amountPaid: event.target.value,
      datePaid: receiptObj.datePaid,
      balance: receiptObj.totalAmount - event.target.value,
    })
  }

  const handleChange = e => {
    setReceiptObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const data = {
    columns: [
      {
        label: "RecNo",
        field: "receiptNo",
        sort: "asc",
        width: 250,
      },
      {
        label: "Customer Name",
        field: "customer_name",
        sort: "asc",
        width: 250,
      },
      {
        label: "invoiceNo",
        field: "invoiceNumber",
        sort: "asc",
        width: 270,
      },

      {
        label: "Amount",
        field: "amountPaid",
        sort: "asc",
        width: 100,
      },

      {
        label: "phonePaid",
        field: "phonePaid",
        sort: "asc",
        width: 100,
      },
      {
        label: "phoneReceipt",
        field: "phoneReceipt",
        sort: "asc",
        width: 100,
      },
      {
        label: "date",
        field: "date",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: ticket?.map(data => {
      data.customer_name = data.customerID?.name
      data.invoiceNumber = data.invoiceID?.invoiceNumber
      data.date = moment(data.datePaid).format("lll")
      // data.Doctor_price = data.Doctor_id?.DrTicketCost
      data.action = (
        <div className="">
          <button
            className="btn   text-primary "
            onClick={() => doctorupdatData(data)}
          >
            {" "}
            <i className="ion ion-md-create font-size-20 "></i>
          </button>
          <button
            className="btn btn-white  text-danger  mx-3 "
            onClick={() => handledoctordelete(data)}
          >
            {" "}
            <i className="ion ion-md-trash font-size-20 "></i>
          </button>
          <button
            className="btn btn-white  text-success  mx-3 "
            onClick={() => handleView(data)}
          >
            {" "}
            <i className="ion ion-md-eye font-size-20 "></i>
          </button>
        </div>
      )
      return data
    }),
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle="Software Decoration"
            title="Sales"
            breadcrumbItem="Receipt"
          />

          <div>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {/* post model start */}

                    <Row>
                      <Col md={10}></Col>
                      <Col md={2}>
                        <button
                          type="button"
                          onClick={() => {
                            tog_standard()
                          }}
                          className="btn waves-effect waves-light"
                          style={{
                            backgroundColor: "#607d8e",
                            color: "white",
                            float: "right",
                          }}
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          Add Receipt
                        </button>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12} md={12}>
                        <div className="my-4 ">
                          <MDBDataTable
                            responsive
                            striped
                            bordered
                            data={data}
                          />
                        </div>
                      </Col>
                    </Row>
                    <ToastContainer />

                    {/* post model end */}
                    <Modal
                      isOpen={isopen}
                      togle={() => {
                        tog_standard()
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          add new Receipt
                        </h5>
                      </div>
                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleSubmit(e, v)
                        }}
                      >
                        <div className="modal-body">
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">
                                  Select Customer
                                </Label>
                                <AvField
                                  name="customerID"
                                  type="select"
                                  errorMessage="Enter Customer name "
                                  className="form-control"
                                  value={receiptObj.customerID}
                                  onChange={event => PatientOnChange(event)}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  id="validationCustom02"
                                >
                                  <option value=""> select Customer</option>
                                  {patientData?.map(cust => {
                                    return (
                                      <option value={cust._id}>
                                        {" "}
                                        {cust.name}
                                      </option>
                                    )
                                  })}
                                </AvField>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">
                                  select invoice
                                </Label>
                                <AvField
                                  name="Doctor_id"
                                  type="select"
                                  errorMessage="Enter Doctor_name "
                                  value={receiptObj.Doctor_id}
                                  onChange={event => InvoiceOnchange(event)}
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  id="validationCustom02"
                                >
                                  <option value=""> select invoice</option>
                                  {invoiceData?.map(invoice => {
                                    return (
                                      <option value={invoice._id}>
                                        {" "}
                                        {invoice.invoiceNumber}
                                      </option>
                                    )
                                  })}
                                </AvField>
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Total Amount</Label>
                                <AvField
                                  name="totalAmount"
                                  placeholder="enter you amount"
                                  value={receiptObj.totalAmount}
                                  onChange={event => handleChange(event)}
                                  type="number"
                                  disabled
                                  errorMessage="Enter you Amount"
                                  className="form-control"
                                  //   validate={{
                                  //     required: { value: true },
                                  //   }}
                                  id="validationCustom02"
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>amountPaid</Label>
                                <AvField
                                  name="amountPaid"
                                  // max={receiptObj.totalAmount-receiptObj.phonePaid}
                                  placeholder="enter you amountPaid"
                                  value={receiptObj.amountPaid}
                                  onChange={event => AmountPaidOnChange(event)}
                                  type="number"
                                  errorMessage="Enter you amountPaid"
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  id="validationCustom02"
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>phonePaid</Label>
                                <AvField
                                  name="phonePaid"
                                  placeholder="enter phonePaid"
                                  type="number"
                                  value={receiptObj.phonePaid}
                                  onChange={event => handleChange(event)}
                                  errorMessage="Enter phonePaid"
                                  className="form-control"
                                  // validate={{
                                  //   required: { value: true },
                                  // }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>phoneReceipt</Label>
                                <AvField
                                  name="phoneReceipt"
                                  placeholder="enter phoneReceipt"
                                  type="number"
                                  value={receiptObj.phoneReceipt}
                                  onChange={event => handleChange(event)}
                                  errorMessage="Enter phoneReceipt"
                                  className="form-control"
                                  // validate={{
                                  //   required: { value: true },
                                  // }}
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Date (optional)</Label>
                                <AvField
                                  name="datePaid"
                                  placeholder="enter you balance"
                                  type="datetime-local"
                                  value={receiptObj.datePaid}
                                  onChange={event => handleChange(event)}
                                  errorMessage="Enter you datePaid"
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  id="validationCustom02"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>balance:</Label>
                                <AvField
                                  name="balance"
                                  placeholder="enter you balance"
                                  type="number"
                                  disabled
                                  value={receiptObj.balance}
                                  onChange={event => handleChange(event)}
                                  errorMessage="Enter you balance"
                                  className="form-control"
                                  //   validate={{
                                  //     required: { value: true },
                                  //   }}
                                  id="validationCustom02"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            onClick={() => {
                              tog_standard()
                            }}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            {loading ? "saving..." : "Save Receipt"}
                          </button>
                        </div>
                      </AvForm>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* print model */}
            <Modal isOpen={showModel} toggle={togle}>
              {/* <ModalHeader toggle={tog_standard}>Qoutation full info</ModalHeader> */}
              <AvForm>
                <div
                  id="printablediv"
                  ref={componentRef}
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Col md={9}>
                      <ModalBody>
                        <Row className="qoutation-title  ">
                          <div className="col-1"> </div>
                          <div className="col-10">
                            <img
                              crossorigin="anonymous"
                              src={headerImage}
                              width="100%"
                              alt="logo"
                            />
                            <div className="row ">
                              <div className="col-2"></div>

                              <div className="col-10">
                                <h5 className="" style={{ marginTop: "-20" }}>
                                  {/* <strong>From:</strong> */}
                                  <br />
                                  Tell:0613661161/0615916607/0611874592
                                  <br />
                                  Add:Buulo shiidle,Mogdishu-Somalia
                                  <br />
                                  {/* <br /> */}
                                </h5>
                                {/* <h4><u>RECEIPT LETTER</u></h4><br/>
                          <h4><u>QUEUE NO:{viewData.ticketNo}</u></h4><br/> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-1"> </div>

                          {/* <div  className="col-6 ">
                  <address>
                            <h3>lab Result</h3>
                          </address>

                  </div> */}
                        </Row>

                        <Row className="mt-4">
                          <div className="col-2 mt-2"></div>
                          <div className="col-4 mt-2">
                            {/* <h5> */}
                            <h4>
                              <u>RECEIPT </u>
                            </h4>
                            <br />

                            {/* </h5> */}
                          </div>
                          <div className="col-4 mt-2 ">
                            {/* <h5> */}
                            <h5>RNO:{viewData.receiptNo}</h5>
                            <br />
                            {/* <strong>sex: {viewData.pateint_id?.Gender}</strong><br/>
                            <strong>labNo:{viewData.labID}</strong>  */}

                            {/* </h5> */}
                          </div>
                          <div className="col-2 mt-2"></div>
                        </Row>

                        <Row className="">
                          {/* <div className="col-2 mt-2"></div> */}
                          <div className="col-6 mt-2">
                            <p>
                              <strong>BEIING:invoice </strong>
                              <br />
                              <strong>
                                REFNO:#{viewData.invoiceID?.invoiceNumber}{" "}
                              </strong>
                              <br />
                            </p>
                          </div>
                          <div className="col-6 mt-2 ">
                            <p>
                              <strong>PRINT DATE:{viewData.date} </strong>
                              <br />
                              <strong>ISSUE DATE:#{viewData.date} </strong>
                              <br />
                            </p>
                          </div>
                          {/* <div className="col-2 mt-2"></div> */}
                        </Row>

                        <div className="table-responsive ">
                          <Table className="table-responsive">
                            <thead>
                              <tr>
                                {/* <th scope="col">NO</th> */}
                                <th scope="col">Patient Name</th>
                                <th scope="col">customerID</th>
                                <th scope="col">Amount Paid</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{viewData.customerID?.Name}</td>
                                <td>{viewData.customerID?.pID}</td>
                                <td>${viewData.amountPaid}</td>
                                {/* <td>{subdes.price}</td>
                                        <td>{subdes.result}</td> */}
                              </tr>
                            </tbody>
                          </Table>

                          <p>Date:{viewData.date}</p>
                          {/* <p>Doctor :{}</p> */}
                          <p> Receipt By:{viewData.userID?.fullName}</p>
                          <p> sign:_______________</p>
                          {/* <p>F.G:Fadlan Soo Noqoshadu Waa (7) Maalmood Gudahood</p>
                  <p>Uqaado Dawada Sida Dhaqtarku Kuugu Qoray</p> */}

                          {/* <Row>
                    <Col xs={6}>
                    <p>Date:{viewData.date}</p>

                    </Col>
                    <Col xs={6}>
                    <p>Doctor sign:</p>

                    </Col>
                  </Row> */}

                          {/* <Row>
                        <div className="col-6 ">
                          <address>
                            <strong>Payment Method:Evc</strong>
                            <br />
                            Phone Paid: {viewData.phonePaid}
                            <br />
                            Receipt by:{viewData.userReceipt}
                          </address>
                        </div>

                      </Row> */}
                          {/* <Row className="  ">
                    <p>Thank you dear student</p>
                  </Row> */}

                          {/* <Row className="qoutation-title" style={{marginLeft:'-50px'}}>
                  <div  className="col-6">
                    <img
                      crossorigin="anonymous"
                      src={footerImage}
                      height="20"
                      width="450"
                      alt="logo"
                    />
                  </div>


                </Row> */}
                        </div>
                      </ModalBody>
                    </Col>
                    <Col></Col>
                  </Row>
                </div>

                <ModalFooter>
                  <Button
                    type="button"
                    onClick={togle}
                    color="danger"
                    className="waves-effect"
                  >
                    Close
                  </Button>
                  <Button
                    color="success"
                    type="button"
                    // disabled={disabled}
                    onClick={handlePrint}
                    className="waves-effect waves-light ml-4 d-print-none"
                  >
                    <i className="ri-printer-line align-middle "></i>
                    {/* {load ?"downloading...":"Download"} */}
                    print
                  </Button>
                </ModalFooter>
              </AvForm>
            </Modal>

            {/* sweetAlert */}
            {deletealert ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={handledeletedoctor}
                onCancel={() => setdeletealert(false)}
              >
                You won't be able to revert this!
              </SweetAlert>
            ) : null}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SalesReceipts
