import { MDBDataTable } from "mdbreact"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table,
} from "reactstrap"

// import "../Dashboard/datatables.scss"
import { AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import axios from "axios"
import { Field, FieldArray, Form, Formik } from "formik"
import { useLocation } from "react-router-dom"
import Select from "react-select"
import { useReactToPrint } from "react-to-print"
import useSWR, { mutate } from "swr"
import headerImage from "../../assets/images/softwarelogo.png"
import Auhtentication from "../../authMidlleware/userMiddleWare"

const Invoice = () => {
  const [enrollmentObj, setenrollmentObj] = useState({
    studentID: "",
    courseID: "",
    classID: "",
    Amount: "",
    enrolledDate: "",
    enrolledStatus: "",
  })

  const [isOpen, setIsOpen] = useState(false)
  const [collapse, setcollapse] = useState(false)
  const [Icon, setIcon] = useState({
    iconPlusOrminus: " btn-warning float-right ri-add-fill font-size-17",
  })
  const [invoiceObj, setinvoiceObj] = useState({})
  const [isEdit, setisEdit] = useState(false)

  const [editID, seteditID] = useState("")

  const [patient, setpatient] = useState([])
  const [labtest, setlabtest] = useState([])
  const [labtestTemp, setlabtestTemp] = useState([])
  const [drugs, setdrugs] = useState([])
  const [viewData, setviewData] = useState({})
  const [showModel, setshowModel] = useState(false)
  const [sweetAlertConfirm, setsweetAlertConfirm] = useState(false)

  const token = sessionStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  const location = useLocation()
  Auhtentication(location.pathname)

  let url = process.env.REACT_APP_DECORATION_ENDPOINT

  async function fetchAll(path) {
    const { data } = await axios.get(url + "/" + path, headers)
    return data
  }

  useEffect(() => {
    const load = async () => {
      try {
        const { data: patientList } = await axios.get(
          `${url}/customers`,
          headers
        )
        // const { data:LabtestList } = await axios.get(`${url}/labLatest`,headers)
        const { data: drugList } = await axios.get(`${url}/stock`, headers)

        if (Array.isArray(drugList)) {
          setdrugs(drugList)
        }
        if (Array.isArray(patientList)) {
          setpatient(patientList)
        }
        // if (Array.isArray(patientList)) {
        //   setpatient(patientList)
        // }
        // if (Array.isArray(LabtestList)) {
        //   setlabtestTemp(LabtestList)
        //   setlabtest(LabtestList)
        // }
      } catch (error) {
        console.log(error.message)
      }
    }
    load()
  }, [])

  //   useSwr("branch",getMyprint);
  let { data: invoiceList } = useSWR("invoice", fetchAll)
  let invoiceData = []

  let chechArray = Array.isArray(invoiceList)
  if (chechArray == true) {
    invoiceData = invoiceList
  }
  const notify = (message, type) => toast[type](message)
  const tog_standard = () => {
    setIsOpen(!isOpen)
    setenrollmentObj({
      studentID: "",
      courseID: "",
      classID: "",
      Amount: "",
      enrolledDate: "",
      enrolledStatus: "",
    })
    setEditCondition(false)
  }

  const handleDelete = data => {
    setenrollmentObj(data)
    setsweetAlertConfirm(true)
  }

  const initialValues = {
    customerID: "",
    discount: 0,
    items: [
      {
        itemID: "",
        Quantity: 1,
        Price: 0,
        Amount: 0,
      },
    ],
  }

  const handleSubmit = async (values, { resetForm }) => {
    try {
      // if (editCondition) {
      //   const { data } = await axios.put("treatment", values,headers)
      //   const { status, message, info } = { ...data }
      //   if (status) {
      //     mutate("treatment", fetchAll)
      //     notify(message, "success")
      //     setIsOpen(false)

      //   }
      // } else {
      // values.doctorID="645e7493eb649f72804db9ed"
      // values.treatmentItems=values.items
      // delete values.items
      // console.log("values", values)

      const { data } = await axios.put(
        `${url}/invoice/${values.id}`,
        values,
        headers
      )
      const { status, message, info } = { ...data }
      if (status) {
        mutate("invoice", fetchAll)

        toast.success(message)
        setIsOpen(false)
        setcollapse(false)
        resetForm()
      } else {
        toast.error(message)
      }
      // }
    } catch (error) {
      notify(error.message, "error")
    }
  }
  const collapseToggle = () => {
    toast.warning("Dear user use custom invoice to make sales thank you")
    return
    setcollapse(!collapse)
    if (collapse) {
      setIcon({
        iconPlusOrminus: "btn-warning float-right ri-add-fill font-size-17",
      })
    } else {
      setIcon({
        iconPlusOrminus: "btn-warning float-right ri-add-fill font-size-17",
      })
    }
  }
  let togle = () => {
    setshowModel(!showModel)
  }

  let handleView = data => {
    setviewData(data)
    setshowModel(true)
  }

  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "invoice",
  })
  const handleEdit = editdata => {
    try {
      const data = { ...editdata }
      setisEdit(true)

      // data.patientID=data.patientID?._id
      // data.studentID = data.studentID?._id;
      setinvoiceObj(data)
      setcollapse(true)
      setIcon({
        iconPlusOrminus: "btn-warning float-right ri-add-fill font-size-17",
      })
      window.scrollTo(0, 100)
      // console.log(data);
    } catch (error) {}
  }

  const data = {
    columns: [
      {
        label: "invNo",
        field: "invoiceNumber",
        sort: "asc",
        width: 270,
      },
      {
        label: "Customer Name",
        field: "customerName",
        sort: "asc",
        width: 150,
      },

      {
        label: "Total",
        field: "Total",
        sort: "asc",
        width: 200,
      },
      ,
      {
        label: "Discount",
        field: "discount",
        sort: "asc",
        width: 100,
      },
      {
        label: "AmountPaid",
        field: "amountPaid",
        sort: "asc",
        width: 100,
      },
      {
        label: "balance",
        field: "balance",
        sort: "asc",
        width: 100,
      },
      {
        label: "view",
        field: "ViewTreatment",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: invoiceData?.map(hos => {
      const data = { ...hos }
      //   console.log(data)

      data.customerName = data.customerID?.name
      // data.studentName = data.studentID?.stdiD;
      data.courseName = data.courseID?.courseName
      data.labID = data.labID?.labID
      data.doctorName = data.doctorID?.Name

      data.date = moment(data.createdAt).format("lll")

      data.ViewTreatment = (
        <div>
          <button
            className="btn text-success "
            onClick={() => handleView(data)}
          >
            {" "}
            <i className="ion ion-md-eye font-size-20 m-auto"></i>
          </button>
        </div>
      )
      data.action = (
        <div>
          <button
            onClick={() => handleEdit(hos)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
          <button
            onClick={() => handleDelete(data)}
            className="btn btn-white btn-sm mr-4"
          >
            <i className="dripicons-trash text-danger font-size-20"></i>
          </button>
        </div>
      )
      return data
    }),
  }
  return (
    <React.Fragment>
      <div className="page-content">
        {/* <MetaTags>
            <title>Lab | lab</title>
          </MetaTags> */}
        <Container fluid>
          <div>
            <Breadcrumbs
              maintitle="Software Decoration"
              title="Inventory"
              breadcrumbItem="Sales"
            />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardHeader className="bg-white">
                    <Button
                      style={{ backgroundColor: "#14213D", color: "white" }}
                      onClick={collapseToggle}
                    >
                      Add New invoice
                    </Button>
                  </CardHeader>

                  <Collapse isOpen={collapse}>
                    <CardBody>
                      {/* <CardTitle className="mb-4">Invoice</CardTitle> */}
                      <Formik
                        enableReinitialize={true}
                        initialValues={
                          isEdit
                            ? {
                                id: invoiceObj._id,
                                customerID: invoiceObj.customerID?._id,
                                invoiceNumber: invoiceObj.invoiceNumber,
                                discount: invoiceObj.discount,
                                subTotal: invoiceObj.subTotal,
                                Total: invoiceObj.Total,
                                note: invoiceObj.note,

                                items: invoiceObj.items?.map(item => ({
                                  id: item._id,
                                  itemID: item.itemID._id,
                                  Quantity: item.Quantity,
                                  Price: item.Price,
                                  Amount: item.Quantity * item.Price,
                                })),
                              }
                            : initialValues
                        }
                        // validationSchema={invoiceSchema}
                        onSubmit={handleSubmit}
                      >
                        {({ values, setFieldValue, errors, touched }) => (
                          <Form>
                            <Row className="mb-4">
                              <Col lg={8} className="mb-3">
                                <FormGroup>
                                  <Label>patient Name</Label>
                                  <Select
                                    name="customerID"
                                    placeholder="select Customer"
                                    required
                                    onChange={event => {
                                      setFieldValue(`customerID`, event.value)
                                    }}
                                    options={patient?.map((patient, index) => {
                                      return {
                                        label:
                                          patient.name + "-" + patient.phone,
                                        value: patient._id,
                                        key: index,
                                      }
                                    })}
                                  ></Select>
                                </FormGroup>

                                {errors.customerID && touched.customerID ? (
                                  <span className="text-danger">
                                    {errors.customerID}
                                  </span>
                                ) : null}
                              </Col>
                            </Row>

                            <div className=" px-3 bg-light mb-4 rounded-top">
                              <div className="row text-white bg-dark py-2 mb-4 rounded-top">
                                <div className="col-lg-3 ">Item Name</div>{" "}
                                <div className="col-md-3 ">Quantity</div>
                                <div className="col-md-2 ">Price</div>{" "}
                                <div className="col-md-2 ">Amount</div>
                              </div>

                              <FieldArray name="items">
                                {({ insert, remove, push }) => (
                                  <div>
                                    {values.items.length > 0 &&
                                      values.items?.map((item, index) => (
                                        <>
                                          <Row key={index}>
                                            <Col lg={3} className="mb-3">
                                              <Label>select Item</Label>

                                              <Field
                                                as="select"
                                                name={`items.${index}.itemID`}
                                                required
                                                className="form-control"
                                                placeholder="Select Item "
                                                onChange={event => {
                                                  let currentDrugs =
                                                    drugs?.find(
                                                      drug =>
                                                        drug._id ==
                                                        event.target.value
                                                    )
                                                  console.log(
                                                    "currentDrugs",
                                                    currentDrugs
                                                  )
                                                  setFieldValue(
                                                    `items.${index}.Price`,
                                                    currentDrugs.sellingPrice ??
                                                      0
                                                  )
                                                  setFieldValue(
                                                    `items.${index}.itemID`,
                                                    event.target.value
                                                  )
                                                  setFieldValue(
                                                    `items.${index}.Quantity`,
                                                    1
                                                  )
                                                  // setFieldValue(
                                                  //   `subTotal`,10
                                                  // );
                                                }}
                                              >
                                                <option value="">
                                                  Choose Drug
                                                </option>
                                                {drugs?.map(drug => (
                                                  <option value={drug._id}>
                                                    {drug.itemName}
                                                  </option>
                                                ))}
                                              </Field>
                                            </Col>

                                            <Col lg={2} className="mb-3">
                                              <Label>Price</Label>
                                              <Field
                                                // disabled

                                                required
                                                name={`items.${index}.Price`}
                                                // value={gerserviceprice(item.itemID)}
                                                className="form-control"
                                                placeholder="Enter Price"
                                                type="text"
                                              />
                                            </Col>
                                            <Col lg={2} className="mb-3">
                                              <Label>Qountity</Label>
                                              <Field
                                                required
                                                minLength="1"
                                                name={`items.${index}.Quantity`}
                                                className="form-control"
                                                placeholder="Enter quantity"
                                                type="number"
                                              />
                                            </Col>

                                            <Col lg={3} className="mb-3">
                                              <Label>Amount</Label>
                                              <Field
                                                name={`items.${index}.Amount`}
                                                className="form-control"
                                                placeholder="Enter Amount"
                                                type="number"
                                                value={
                                                  Number(item.Quantity) *
                                                  Number(item.Price)
                                                }
                                                disabled
                                              />
                                            </Col>

                                            {/* <Col lg={2} className="mb-3">
                                        <Label>Delete</Label>
                                        <Row>
                                        <button
                                          type="button"

                                          className="btn btn-danger form-control"
                                          onClick={() => remove(index)}
                                        >
                                          <i className="ion ion-md-trash "></i>
                                        </button>

                                        <button
                                  type="button"
                                  className="btn btn-dark mb-3 mx-2"
                                  onClick={() =>
                                    push({
                                      itemID: "",
                                      Quantity: "",
                                      Price: "",
                                      Amount: 0,
                                    })
                                  }
                                >
                                  Add  Item
                                </button>
                                </Row>
                                      </Col> */}
                                            <Col lg={2} className="mb-3 mt-4">
                                              <button
                                                type="button"
                                                color="danger"
                                                className="dripicons-trash btn btn-danger"
                                                onClick={() => remove(index)}
                                              >
                                                <i className=" ri-delete-bin-line"></i>
                                              </button>

                                              <button
                                                type="button"
                                                className="btn btn-dark mx-2"
                                                onClick={() =>
                                                  push({
                                                    itemID: "",
                                                    Quantity: 1,
                                                    Price: 0,
                                                    Amount: 0,
                                                  })
                                                }
                                              >
                                                Add item
                                              </button>
                                            </Col>
                                          </Row>
                                          <hr />
                                        </>
                                      ))}
                                  </div>
                                )}
                              </FieldArray>
                            </div>
                            {/* <div className="text-right">
                           <p>Subtotal : <b>{values.items.reduce((prev,item) => (Number(item.Price)*Number(item.Quantity)) + prev,0)}</b></p>
                        <p>Discount : <b><Field type='number' name='discount' max={values.items.reduce((prev,item) =>  (Number(item.Price)*Number(item.Quantity)) + prev,0)} className='form-control form-control-sm form-inline' /></b></p>
                        <p>Grand Total : <b>{values.items.reduce((prev,item) => (Number(item.Price)*Number(item.Quantity)) + prev,0) - values.discount}</b></p>
                        </div> */}

                            <Row className="row justify-content-end mb-warning">
                              <Col className="col-lg-6 col-xl-5">
                                <div className="text-size-16 border-bottom mb-2">
                                  <div className="d-flex align-items-center justify-content-between py-2">
                                    <p class="text-muted mb-0">Sub Total</p>
                                    <Field
                                      type="hidden"
                                      name="subTotal"
                                      value={values.items?.reduce(
                                        (prev, item) =>
                                          Number(item.Price) *
                                            Number(item.Quantity) +
                                          prev,
                                        0
                                      )}
                                      class="form-control "
                                    ></Field>
                                    <h6 class="mb-0">
                                      $
                                      {values.items?.reduce(
                                        (prev, item) =>
                                          Number(item.Price) * item.Quantity +
                                          prev,
                                        0
                                      )}
                                    </h6>
                                  </div>

                                  <div className="row d-flex align-items-center justify-content-between py-2">
                                    <div className="col-7">
                                      <p class="text-muted mb-0">Discount </p>
                                    </div>
                                    <div className="col-5">
                                      <Field
                                        type="number"
                                        name="discount"
                                        required
                                        max={values.items.reduce(
                                          (prev, item) =>
                                            Number(item.Price) *
                                              Number(item.Quantity) +
                                            prev,
                                          0
                                        )}
                                        class="form-control "
                                      ></Field>
                                    </div>
                                  </div>

                                  {/* main Total div */}
                                  <div className="d-flex align-items-center justify-content-between py-2">
                                    <p class="text-muted mb-0">Grand Total</p>
                                    <Field
                                      type="hidden"
                                      name="Total"
                                      value={
                                        values.items.reduce(
                                          (prev, item) =>
                                            Number(item.Price) *
                                              Number(item.Quantity) +
                                            prev,
                                          0
                                        ) - Number(values.discount)
                                      }
                                      class="form-control "
                                    ></Field>
                                    <h4 class="mb-0">
                                      $
                                      {values.items.reduce(
                                        (prev, item) =>
                                          Number(item.Price) *
                                            Number(item.Quantity) +
                                          prev,
                                        0
                                      ) - Number(values.discount)}
                                    </h4>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={12} className="mb-3">
                                <label htmlFor="quantity"> Note</label>
                                <Field
                                  as="textarea"
                                  name="note"
                                  className="form-control"
                                  placeholder="Enter your note"
                                  // value={invoiceObj.invoiceNumber}
                                  // onChange={(e) => handleChangeInput(e)}
                                  validate={{
                                    required: { value: true },
                                    minLength: { value: 10 },
                                  }}
                                />
                                {errors.note && touched.note ? (
                                  <span className="text-danger">
                                    {errors.note}
                                  </span>
                                ) : null}
                              </Col>
                            </Row>

                            <hr />
                            <Button
                              type="submit"
                              // color="warning"
                              className="btn-rounded mb-2  me-2 btn btn-success float-right "
                            >
                              <i className="mdi mdi-plus mr-2"></i> Save Invoice
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable responsive striped bordered data={data} />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Modal isOpen={showModel} toggle={togle}>
              {/* <ModalHeader toggle={tog_standard}>Qoutation full info</ModalHeader> */}
              <AvForm>
                {/* ref={reportTemplateRef} */}
                <div id="printablediv" ref={componentRef}>
                  <ModalBody>
                    <Row className="qoutation-title  ">
                      <div className="col-1"> </div>
                      <div className="col-10">
                        <img
                          crossorigin="anonymous"
                          src={headerImage}
                          width="100%"
                          alt="logo"
                        />
                        <div className="row ">
                          <div className="col-2"></div>

                          <div className="col-10">
                            <h5 className="" style={{ marginTop: "-20" }}>
                              {/* <strong>From:</strong> */}
                              <br />
                              Tell:0613661161/0615916607
                              <br />
                              Add:Buulo shiidle,mogdishu-somalia
                              <br />
                              {/* <br /> */}
                            </h5>
                            <h4>
                              <u>Invoice</u>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-1"> </div>

                      {/* <div  className="col-6 ">
                  <address>
                            <h3>lab Result</h3>
                          </address>

                  </div> */}
                    </Row>

                    <Row className="">
                      {/* <div className="col-2 mt-2"></div> */}
                      <div className="col-6 mt-2">
                        <p>
                          {/* <strong>Name </strong><br/> */}
                          <strong>Name:{viewData.customerID?.name} </strong>
                          <br />

                          <br />
                          <strong>invoiceNo:{viewData.invoiceNumber} </strong>
                          <br />
                        </p>
                      </div>
                      <div className="col-6 mt-2 ">
                        <p>
                          <br />
                          <strong>
                            Gender :{viewData.customerID?.gender}{" "}
                          </strong>
                          <br />
                        </p>
                      </div>
                      {/* <div className="col-2 mt-2"></div> */}
                    </Row>

                    <div className="table-responsive ">
                      <Table className="table-responsive mt-4">
                        <thead>
                          <tr>
                            {/* <th scope="col">NO</th> */}
                            <th scope="col">Item</th>
                            {/* <th></th>
                        <th></th> */}
                            <th scope="col">Quantity</th>
                            <th scope="col">price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {viewData.items?.map(subdes => (
                            <tr>
                              <td>{subdes.itemID?.itemName}</td>
                              <td>{subdes.Quantity}</td>
                              <td>${subdes.Price}</td>
                              {/* <td>{subdes.route}</td> */}
                            </tr>
                          ))}
                          <tr>
                            <td> </td>
                            <td>Sub Total </td>
                            <td>${viewData.Total + viewData.discount}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>discount</td>
                            <td>${viewData.discount}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Total Amount</td>
                            <td>${viewData.Total}</td>
                          </tr>

                          <tr>
                            <td></td>
                            <td>Paid</td>
                            <td>${viewData.amountPaid}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Balance</td>
                            <td>${viewData.balance}</td>
                          </tr>
                        </tbody>
                      </Table>

                      <p>Date:{viewData.date}</p>
                      <p>user:{viewData?.userID?.fullName}</p>
                      {/* <p>Doctor :{}</p> */}
                      <p> sign:_______________</p>
                    </div>
                  </ModalBody>
                </div>
                <ModalFooter>
                  <Button
                    type="button"
                    onClick={togle}
                    color="danger"
                    className="waves-effect"
                  >
                    Close
                  </Button>
                  <Button
                    color="success"
                    type="button"
                    // disabled={disabled}
                    //   onClick={handleGeneratePdf}
                    onClick={handlePrint}
                    className="waves-effect waves-light ml-4 d-print-none"
                  >
                    <i className="ri-printer-line align-middle "></i>
                    {/* {load ?"downloading...":"Download"} */}
                    print
                  </Button>
                </ModalFooter>
              </AvForm>
            </Modal>

            <ToastContainer />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Invoice
