import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import {Container} from "reactstrap"
import "../Dashboard/datatables.scss"
import ClassTable from "./classTable"
import ClassForm from "./classForm"
import moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ClassContextProvider from "../../context/classContext"

const classes = () => {
 
  return (
    <React.Fragment>
      <ClassContextProvider>
      <div className="page-content">
        <MetaTags>
          <title>
            Class | easy soft
          </title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs maintitle="Veltrix" title="class" breadcrumbItem="class" />
             <ClassForm/>
              <ClassTable/>
          </div>
        </Container>
      </div>
      </ClassContextProvider>
    </React.Fragment>
  )
}

export default classes
