import React, { useState,useContext } from "react"
import MetaTags from "react-meta-tags"
import {
  Col,
  Container,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  ModalFooter,
  Card,
  CardBody,
  CardTitle,

} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import "../Dashboard/datatables.scss"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"
import {subscriptionContext} from "../../context/subscriptionContext"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const SubscriptionTable = () => {
  const { isOpen,editCondition,
    subscriptionData, 
    tog_standard, 
    handleEdit } =
  useContext(subscriptionContext);

  const data = {
    columns: [
      {
        label: "Term",
        field: "subscriptoinTerm",
        sort: "asc",
        width: 150,
      },
      {
        label: "Amount",
        field: "amountPaid",
        sort: "asc",
        width: 270,
      },
      {
        label: "accountType",
        field: "accountType",
        sort: "asc",
        width: 200,
      },

      {
        label: "PhonePaid",
        field: "PhonePaid",
        sort: "asc",
        width: 100,
      },
      {
        label: "date Paid",
        field: "datePaid",
        sort: "asc",
        width: 100,
      },
      {
        label: "expired Date",
        field: "dateExpired",
        sort: "asc",
        width: 100,
      },
      {
        label: "Created Date",
        field: "date",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: subscriptionData?.map(hos => {
      const data = { ...hos }     
      data.datePaid = moment(data.datePaid).format("lll")
      data.dateExpired = moment(data.expiredDate).format("lll")
      data.date = moment(data.createdAt).format("lll")
      data.amountPaid="$" + data.amountPaid
      data.action = (
        <div>
          <button
            onClick={() => handleEdit(hos)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#0A928C",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
        
        </div>
      )
      return data
    }),
  }
  return (
    <Row  >
      <Col  sm={6} md={12} xl={12} >
        <Card>
          <CardBody>
            <Row className="align-items-center  mb-3">
              <Col xs={6}>
                <CardTitle className="h4">Courses Subscription </CardTitle>
              </Col>

              <Col xs={6} >
                <div className="float-end d-none d-md-block">
                  <Button
                    onClick={tog_standard}
                    style={{
                        background: "#14213D",
                        color: "white",
                        border: "#0A928C",
                      }}
                    className=" dropdown-toggle waves-effect waves-light"
                  >
                    <i className="ion ion-md-add me-2"></i> Add Subscription
                  </Button>
                </div>
              </Col>
            </Row>
            <ToastContainer />

            <MDBDataTable responsive striped bordered data={data} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default SubscriptionTable
