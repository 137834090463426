import { MDBDataTable } from "mdbreact"
import moment from "moment"
import { useContext } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { feeContext } from "../../context/feeContext"
import "../Dashboard/datatables.scss"

const FeeTable = () => {
  const {
    isOpen,
    editCondition,
    feeData,
    tog_standard,
    Receipt_tog,
    handleEdit,
    setsweetAlertConfirm,
    sweetAlertConfirm,
    handleDelete,
  } = useContext(feeContext)

  const data = {
    columns: [
      {
        label: "feeNo",
        field: "feeNo",
        sort: "asc",
        width: 150,
      },
      {
        label: "StudentName",
        field: "studentName",
        sort: "asc",
        width: 150,
      },

      {
        label: "Course",
        field: "courseName",
        sort: "asc",
        width: 270,
      },
      {
        label: "Class",
        field: "className",
        sort: "asc",
        width: 270,
      },
      {
        label: "feeName",
        field: "feeName",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total",
        field: "totalAmount",
        sort: "asc",
        width: 200,
      },

      {
        label: "Paid",
        field: "Paid",
        sort: "asc",
        width: 100,
      },
      {
        label: "Balance",
        field: "Balance",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "GeneratedDate",
        field: "date",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "receipt",
      //   field: "receipt",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: feeData?.map(hos => {
      const data = { ...hos }

      // data.studentName = data.studentID?.studentName;
      // data.stdID = data.studentID?.stdID
      data.studentName =
        data.studentID?.studentName + "-" + data.studentID?.stdID
      data.courseName = data.courseID?.courseName
      data.className = data.classID?.className

      data.date = moment(data.createdAt).format("lll")

      data.totalAmount = "$" + data.feeAmount
      data.Paid = "$" + data.feePaid
      data.Balance = "$" + data.feeBalance

      if (data.feeStatus == "percialPaid") {
        data.status = "percial Paid"
        data.status = (
          <div className="badge bg-info  font-size-15 badge-pill mr-2">
            {data.feeStatus}
          </div>
        )
      } else if (data.feeStatus == "fullPaid") {
        data.status = "Full paid"
        data.status = (
          <div className="badge bg-success font-size-15 badge-pill mr-2">
            {data.feeStatus}
          </div>
        )
      } else {
        data.status = "un paid"
        data.status = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.feeStatus}
          </div>
        )
      }
      // data.receipt = (
      //   <div>
      //    <button
      //       onClick={() => Receipt_tog(hos)}
      //       type="button"
      //       className="btn btn-white btn-sm mr-4"
      //     >
      //       <i
      //         style={{
      //           color: "#0A928C",
      //         }}
      //         className="ion ion-ios-paper font-size-20 "
      //       ></i>
      //     </button>

      //   </div>
      // )
      data.action = (
        <div>
          <button
            onClick={() => handleEdit(hos)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
          <button
            onClick={() => handleDelete(data)}
            className="btn btn-white btn-sm mr-4"
          >
            <i className="dripicons-trash text-danger font-size-20"></i>
          </button>
        </div>
      )
      return data
    }),
  }
  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <Row className="align-items-center  mb-3">
              <Col md={8}>
                <CardTitle className="h4">Fee Table </CardTitle>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Button
                    onClick={tog_standard}
                    style={{
                      background: "#14213D",
                      color: "white",
                      border: "#0A928C",
                    }}
                    className=" dropdown-toggle waves-effect waves-light"
                  >
                    <i className="ion ion-md-add me-2"></i> Add Fee
                  </Button>
                </div>
              </Col>
            </Row>
            <ToastContainer />

            <MDBDataTable responsive striped bordered data={data} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default FeeTable
