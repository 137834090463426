import React, { createContext, useState, useEffect } from "react"
import moment from "moment"
import {
    GetAll,
    // fetchAll,
    postfunc,
    putfunc,
    deletefunc
} from "../services/mainService"
import axios from "axios"
import useSwr,{mutate,trigger,useSWRConfig} from "swr"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom"
import Auhtentication from ".././authMidlleware/userMiddleWare"
export const classContext = createContext()
classContext.displayName = "classContext"

const ClassContextProvider = props => {
    const [classObj, setclassObj] = useState({
        className: "",
        courseID: "",
        teacherID: "", 
        days:"",
        classShift:""  , 
        startedDate:"",
        endDate:"",
        TimeIn:"",
        TimeOut:"",
        classStatus:"",
      })
      const [coursesData, setcourses] = useState([])
      const [teachers, setteachers] = useState([])
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState([]);
  const [disabled, setdisabled] = useState(false)
const [load, setload] = useState(false)
const [sweetAlertConfirm, setsweetAlertConfirm] = useState(false)

const token = localStorage.getItem("token");
const headers = {
  headers: {
    "Content-Type": "application/json",
    "auth-token": token
  },
};
const location = useLocation();


  useEffect(() => {
    const getclass = async () => {
      try {
        const { data } = await GetAll('courses',headers);
        const { data:teachersData } = await GetAll('teacher',headers);
        let chechCourse=Array.isArray(data)
        let chechteacher=Array.isArray(teachersData)
        if(chechCourse ){
            setcourses(data)
        }
        if(chechteacher ){       
            setteachers(teachersData)
        }
    
     
      } catch (error) {
          console.log(error.message);
        
      }
    };
    getclass();

  }, []);
  Auhtentication(location.pathname)
 
let epiEndPoint=process.env.REACT_APP_ENDPOINT
  async function fetchAll(path) {
    const {data}=await axios.get(epiEndPoint+"/"+path,headers);
    return data
};

let { data:classeslist } = useSwr("class",fetchAll)

let classesData=[]
let chechArray=Array.isArray(classeslist)

if(chechArray==true){
  classesData=classeslist
}
  const notify = (message, type) => toast[type](message);
  const tog_standard = () => {
    setIsOpen(!isOpen)
    setclassObj({
      className: "",
      courseID: "",
      teacherID: "", 
      days:"",
      classShift:""  , 
      startedDate:"",
      endDate:"",
      TimeIn:"",
      TimeOut:"",
      classStatus:"",
    })
    setSelected([])
    setEditCondition(false)
  }
  
  const HandleSubmitBtn = async e => {
    try {
      setdisabled(true)
      setload(true)
      if (editCondition) {
        classObj.days=selected
        const {data} = await putfunc("class",classObj,headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("class", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setclassObj({
            cardName: "",
            amount: "",
            maxSize: "",
            minSize: "",
          })
        }else{
          setdisabled(false)
          setload(false)
          notify(message, "error");
        }
      } else {
        // notify("welcome", "error");
        // return console.log("selected", selected)
      // let timein=  moment(classObj.TimeIn,'hh:mm').format('hh:mm a')
      // let timeOut=  moment(classObj.TimeOut,'hh:mm').format('hh:mm a')
      // classObj.TimeIn=timein
      // classObj.TimeOut=timeOut
      if(classObj.classStatus==""){
        delete classObj.classStatus
      }
      classObj.days=selected

        const {data} = await postfunc("class",classObj,headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("class", fetchAll)
          setclassObj({
            className: "",
            courseID: "",
            teacherID: "", 
            classShift:""  , 
            startedDate:"",
            endDate:"",
            TimeIn:"",
            TimeOut:"",
            classStatus:"",
          })
          notify(message, "success")
          setIsOpen(false)

        } else {
          setdisabled(false)
          setload(false)
          notify(message, "error")
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
      setdisabled(false)
          setload(false)
    }
  }

  const handleChange = e => {
    setclassObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const handleEdit = async (transData) => {
    let data = { ...transData };
    data.courseID = data.courseID?._id;
    data.teacherID = data.teacherID?._id;
    setSelected(data.days)
    data.startedDate = moment(data.startedDate).format("YYYY-MM-DD");
    data.endDate = moment(data.endDate).format("YYYY-MM-DD");
    data.date = new Date(data.date);
    setEditCondition(true);
    setclassObj(data);
    setIsOpen(true);
  };
  const handleDelete=(data) => {
    console.log(data)
    setclassObj(data)
    setsweetAlertConfirm(true)

  }
  const options = [
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday"},
    { label: "Tuesday", value: "Tuesday"},
    { label: "Wednesday", value: "Wednesday"},
    { label: "Thursday", value: "Thursday"},
    { label: "Friday", value: "Friday"},
  ];
  // const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  return (
    <classContext.Provider
      value={{
        handleEdit,
        handleChange,
        HandleSubmitBtn,
        isOpen,
        setIsOpen,
        classesData,
        editCondition,
        classObj,
        tog_standard,
        handleEdit,
        tog_standard,
        coursesData,
        teachers,
        options,
        setSelected,
        selected,
        disabled,
        load,
        setsweetAlertConfirm,
        sweetAlertConfirm,
        handleDelete,
        deletefunc,
        fetchAll
      }}
    >
      {props.children}
    </classContext.Provider>
  );


}

export default ClassContextProvider
