import React, { createContext, useState, useEffect } from "react"
import moment from "moment"
import {
    GetAll,
    fetchAll,
    postfunc,
    putfunc,
    deletefunc
} from "../services/mainService"
import useSwr,{mutate,trigger,useSWRConfig} from "swr"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import jwtDecode from "jwt-decode";
import { useLocation } from "react-router-dom"
import Auhtentication from ".././authMidlleware/userMiddleWare"
import axios from "axios"
export const IncomeContext = createContext()
IncomeContext.displayName = "IncomeContext"

const IncomeContextProvider = props => {
    const [IncomeObj, setIncomeObj] = useState({
        userID: "",
        transectionType: "",
        description: "",
        amount:"",
        date:""
      })
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [currentUser, setcurrentUser] = useState({})
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const [sweetAlertConfirm, setsweetAlertConfirm] = useState(false)
  const [brandType, setbrandType] = useState("")
  const location = useLocation();
  Auhtentication(location.pathname)

  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token
    },
  };
  let epiEndPoint=process.env.REACT_APP_ENDPOINT
  const epiEndPointDecoration = process.env.REACT_APP_DECORATION_ENDPOINT

  async function fetchAll(path) {
    let brandType = sessionStorage.getItem("brandType")
    if (brandType == "decoration") {
      epiEndPoint = epiEndPointDecoration
    }

    const {data}=await axios.get(epiEndPoint+"/"+path,headers);
    return data
  };
  useEffect(() => {
    const getclass = async () => {
      try {
        const token = localStorage.getItem("token");
        const user = jwtDecode(token);
        setcurrentUser(user)
        let brandType = sessionStorage.getItem("brandType")
        setbrandType(brandType)

      } catch (error) {
          console.log(error.message);

      }
    };
    getclass();

  }, []);

//   useSwr("branch",getMyprint);
let { data:TransectionList } = useSwr("transection",fetchAll)
let TransectionData=[]

let chechArray=Array.isArray(TransectionList)
if(chechArray==true){
  TransectionData=TransectionList
}

const IncomeData= TransectionData?.filter((i) => i.transectionType=="income");
  const notify = (message, type) => toast[type](message);
  const tog_standard = () => {
    setIsOpen(!isOpen)
    setIncomeObj({
        userID: "",
        transectionType: "",
        description: "",
        amount:"",
        date:""
    })
    setEditCondition(false)
  }

  const HandleSubmitBtn = async e => {
    try {
      setdisabled(true)
      setload(true)
      if (editCondition) {

        const {data} = await putfunc("transection",IncomeObj,headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("transection", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setIncomeObj({
            userID: "",
            transectionType: "",
            description: "",
            amount:"",
            date:""
          })
        }else{
          notify(message, "error");
          setdisabled(false)
          setload(false)
        }
      } else {
        // notify("welcome", "error");
        let mainObj={
            userID: currentUser._id,
            transectionType: "income",
            amount:IncomeObj.amount,
            date:IncomeObj.date,
            description: IncomeObj.description,
            name:IncomeObj.name,
            type:IncomeObj.type
        }
        // return console.log("IncomeObj", mainObj)

        const {data} = await postfunc("transection",mainObj,headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("transection", fetchAll)
          setIncomeObj({
            userID: "",
            transectionType: "",
            description: "",
            amount:"",
            date:""
          })
          notify(message, "success")
          setIsOpen(false)
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
      setdisabled(false)
          setload(false)
    }
  }

  const handleChange = e => {
    setIncomeObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const handleEdit = async (transData) => {
    let data = { ...transData };

    data.userID = data.userID?._id;
    data.date = moment(data.datePaid).format("YYYY-MM-DD[T]HH:mm:ss");
    setEditCondition(true);
    setIncomeObj(data);
    setIsOpen(true);
  };
  const handleDelete=(data) => {
    console.log(data)
    setIncomeObj(data);
    setsweetAlertConfirm(true)

  }
  return (
    <IncomeContext.Provider
      value={{
        handleEdit,
        handleChange,
        HandleSubmitBtn,
        tog_standard,
        isOpen,
        setIsOpen,
        IncomeData,
        editCondition,
        IncomeObj,
        disabled,
        load,
        setsweetAlertConfirm,
        sweetAlertConfirm,
        handleDelete,
        deletefunc,
        fetchAll,
        brandType
      }}
    >
      {props.children}
    </IncomeContext.Provider>
  );


}

export default IncomeContextProvider
