import { MDBDataTable } from "mdbreact"
import moment from "moment"
import { useContext } from "react"
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import "../Dashboard/datatables.scss"

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { EmployeeContext } from "../../context/employeeContext"
const EmployeeTable = () => {
  const { isOpen, editCondition, employee, tog_standard, handleEdit } =
    useContext(EmployeeContext)

  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "phone",
        field: "phone",
        sort: "asc",
        width: 270,
      },
      {
        label: "Gender",
        field: "gender",
        sort: "asc",
        width: 200,
      },

      {
        label: "Address",
        field: "address",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "educationalLevel",
      //   field: "educationalLevel",
      //   sort: "asc",
      //   width: 100,
      // },
      // {
      //   label: "departmentWork",
      //   field: "departmentWork",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "jobTitle",
        field: "jobTitle",
        sort: "asc",
        width: 100,
      },
      {
        label: "dateOfBirth",
        field: "birthdate",
        sort: "asc",
        width: 100,
      },
      {
        label: "salary",
        field: "salaryAmount",
        sort: "asc",
        width: 100,
      },
      {
        label: "employeeStatus",
        field: "employeeStatus",
        sort: "asc",
        width: 100,
      },
      {
        label: "TotalTasks",
        field: "totalTaskRequired",
        sort: "asc",
        width: 100,
      },
      {
        label: "commissionRate",
        field: "commissionRate",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: employee?.map(hos => {
      const data = { ...hos }
      data.birthdate = moment(data.dateOfBirth).format("lll")
      data.price = "$" + data.coursePrice
      data.salaryAmount = "$" + data.salaryAmount
      data.action = (
        <div>
          <button
            onClick={() => handleEdit(hos)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
        </div>
      )
      return data
    }),
  }
  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <Row className="align-items-center  mb-3">
              <Col md={8}>
                <CardTitle className="h4">Employee Table </CardTitle>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Button
                    onClick={tog_standard}
                    style={{
                      background: "#14213D",
                      color: "white",
                      border: "#0A928C",
                    }}
                    className=" dropdown-toggle waves-effect waves-light"
                  >
                    <i className="ion ion-md-add me-2"></i> Add Employee
                  </Button>
                </div>
              </Col>
            </Row>
            <ToastContainer />

            <MDBDataTable responsive striped bordered data={data} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default EmployeeTable
