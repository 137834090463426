import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import { useContext } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import { mutate } from "swr"
import { attendenceContext } from "../../context/attendenceContext"
import "../Dashboard/datatables.scss"
const AttendenceTable = () => {
  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  const {
    tog_standard,
    handleEdit,
    TempenrollmentData,
    attendenceList,
    setsweetAlertConfirm,
    sweetAlertConfirm,
    handleDelete,
    deletefunc,
    fetchAll,
    attendenceObj,
    editattenceObj,
    handleSubmitEdit,
    isOpen,
    load,
    handleChangeEdit,
  } = useContext(attendenceContext)
  const data = {
    columns: [
      {
        label: "student Name",
        field: "studentName",
        sort: "asc",
        width: 150,
      },

      {
        label: "class Name",
        field: "className",
        sort: "asc",
        width: 200,
      },

      {
        label: "Date Attended",
        field: "Date",
        sort: "asc",
        width: 100,
      },
      {
        label: "Day",
        field: "day",
        sort: "asc",
        width: 100,
      },

      {
        label: "attended",
        field: "attended",
        sort: "asc",
        width: 100,
      },
      {
        label: "User attended",
        field: "teacherName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },

      {
        label: "Reason",
        field: "reason",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: attendenceList?.map(hos => {
      const data = { ...hos }

      // data.studentName = data.studentID?.studentName;
      data.studentName =
        data.studentID?.studentName + " -" + data.studentID?.stdID
      data.courseName = data.courseID?.courseName
      data.className = data.classID?.className
      data.teacherName = data.teacherID?.teacherName
      data.username = data.userID?.name
      data.Date = moment(data.createdAt).format("lll")

      if (data.attendenceStatus == "present") {
        data.status = (
          <div className="badge bg-success  font-size-15 badge-pill mr-2">
            {data.attendenceStatus}
          </div>
        )
      } else {
        data.status = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.attendenceStatus}
          </div>
        )
      }

      data.action = (
        <div>
          <button
            onClick={() => handleEdit(hos)}
            className="btn btn-white btn-sm mr-4"
          >
            <i className="mdi mdi-pencil text-success font-size-20"></i>
          </button>
          <button
            onClick={() => handleDelete(data)}
            className="btn btn-white btn-sm mr-4"
          >
            <i className="dripicons-trash text-danger font-size-20"></i>
          </button>
        </div>
      )
      return data
    }),
  }
  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <Modal isOpen={isOpen} toggle={tog_standard}>
              <ModalHeader
                style={{
                  background: "#14213D",
                  color: "white",
                  border: "#0A928C",
                }}
                toggle={tog_standard}
              >
                {" "}
                <p> Edit Attendence </p>
              </ModalHeader>
              <AvForm
                className="needs-validation"
                onValidSubmit={handleSubmitEdit}
              >
                <ModalBody>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <AvField
                          name="studentName"
                          type="text"
                          label="student Name"
                          value={editattenceObj.studentName}
                          onChange={e => handleChangeEdit(e)}
                          placeholder="Enter student Name"
                          errorMessage="Enter student Name"
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <AvField
                          name="className"
                          type="text"
                          label="class name"
                          value={editattenceObj.className}
                          onChange={e => handleChangeEdit(e)}
                          placeholder="Select  class"
                          errorMessage="Select class"
                          validate={{
                            required: { value: true },
                          }}
                        ></AvField>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <AvField
                          name="attended"
                          type="select"
                          label="attended"
                          value={editattenceObj.attended}
                          onChange={e => handleChangeEdit(e)}
                          placeholder="Enter attended "
                        >
                          <option vlaue="1">1</option>
                          <option vlaue="0">0</option>
                        </AvField>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <AvField
                          name="attendenceStatus"
                          type="select"
                          label="attendence Status"
                          value={editattenceObj.attendenceStatus}
                          onChange={e => handleChangeEdit(e)}
                          placeholder="Enter attendence Status"
                        >
                          <option vlaue="upsent">upsent</option>
                          <option vlaue="present">present</option>
                          <option vlaue="sick">sick</option>
                        </AvField>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <AvField
                      name="reason"
                      type="textarea"
                      maxlength="255"
                      label="Enter Reason"
                      placeholder="Enter Reason "
                      value={editattenceObj.reason}
                      onChange={e => handleChangeEdit(e)}
                      errorMessage="Enter reason"
                      validate={{
                        required: { value: false },
                      }}
                    />
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    color="danger"
                    onClick={tog_standard}
                    className="waves-effect"
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    style={{
                      background: "#14213D",
                      color: "white",
                      border: "#0A928C",
                    }}
                    disabled={load}
                    className="waves-effect waves-light"
                  >
                    {load ? "saving..." : " Save attendence"}
                  </Button>
                </ModalFooter>
              </AvForm>
            </Modal>
            <Row className="align-items-center  mb-3">
              <Col md={8}>
                <CardTitle className="h4">Addendence List </CardTitle>
              </Col>

              <Col md="4">
                {/* <div className="float-end d-none d-md-block">
                  <Button
                    onClick={tog_standard}
                    style={{ background: "#0A928C", color: "white" }}
                    className=" dropdown-toggle waves-effect waves-light"
                  >
                    <i className="ion ion-md-add me-2"></i> Add Enrollment
                  </Button>
                </div> */}
              </Col>
            </Row>
            {sweetAlertConfirm ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={async () => {
                  try {
                    // return  console.log(IncomeObj)
                    const { data } = await deletefunc(
                      "attendence",
                      attendenceObj,
                      headers
                    )
                    const { status, message } = { ...data }
                    if (status) {
                      toast.success(message)
                      setsweetAlertConfirm(false)
                      mutate("attendence", fetchAll)
                    } else {
                      toast.error(message)
                    }
                  } catch (error) {
                    toast.error(error.message)
                  }
                }}
                onCancel={() => setsweetAlertConfirm(false)}
              >
                You won't be able to revert this!
              </SweetAlert>
            ) : null}
            <ToastContainer />

            <MDBDataTable responsive striped bordered data={data} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default AttendenceTable
