import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import FeeContextProvider from "../../context/feeContext"
import "../Dashboard/datatables.scss"
import FeeForm from "./feeForm"
import FeeTable from "./feeTable"

const Fee = () => {
  return (
    <React.Fragment>
      <FeeContextProvider>
        <div className="page-content">
          <MetaTags>
            <title>Fee | Software academy</title>
          </MetaTags>
          <Container fluid>
            <div className="page-title-box">
              <Breadcrumbs
                maintitle="Veltrix"
                title="class"
                breadcrumbItem="class"
              />
              <FeeForm />
              <FeeTable />
            </div>
          </Container>
        </div>
      </FeeContextProvider>
    </React.Fragment>
  )
}

export default Fee
