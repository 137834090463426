import React, { createContext, useState, useEffect } from "react"
import {
  Col,
  Container,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  ModalFooter,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
import moment from "moment"
import { GetAll, fetchAll, postfunc, putfunc } from "../../services/mainService"
import { MDBDataTable } from "mdbreact"
import useSwr, { mutate, trigger, useSWRConfig } from "swr"
import { ToastContainer, toast } from "react-toastify"
import { CSVLink } from "react-csv"
import "react-toastify/dist/ReactToastify.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"

const EnrollmentReport = () => {
  const [feeObj, setfeeObj] = useState({
    courseID: "",
    classID: "",
    status: "active",
  })
  const [studentEnrolledData, setstudentEnrolledData] = useState([])
  const [studentTempEnrollment, setstudentTempEnrollment] = useState([])
  const [classesData, setclassesData] = useState([])
  const [classesTemp, setclassesTemp] = useState([])

  const [courseData, setcourseData] = useState([])
  
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token
    },
  };
  useEffect(() => {
    const getfee = async () => {
      try {
        const { data: enrollment } = await GetAll("enrollment",headers)
        const { data: classList } = await GetAll("class",headers)
      
        const { data: course } = await GetAll("courses",headers)

        let checkenrollment=Array.isArray(enrollment)
        let checkclass=Array.isArray(classList)
        let checkcourse=Array.isArray(course)

        if(checkenrollment){
          setstudentEnrolledData(enrollment)
          setstudentTempEnrollment(enrollment)
        }
        if(checkclass){
          setclassesTemp(classList)
        }
        if(checkcourse){
          setcourseData(course)     
        }
      } catch (error) {
        console.log(error.message)
      }
    }
    getfee()
  }, [])

  const notify = (message, type) => toast[type](message)

  const HandleSubmitBtn = async e => {
    try {
      //  return console.log("feeObj",feeObj)
   
        let studentsCourseClass = studentTempEnrollment.filter( att =>
            att.classID?._id == feeObj.classID && att.enrolledStatus== feeObj.status && att.courseID?._id == feeObj.courseID
        )
        // console.log(studentsCourseClass)
      
        setstudentEnrolledData(studentsCourseClass)

      
    } catch (error) {
      console.log(error.response)
    }
  }
  const handleChangesCourse = e => {
    if (e.target.value ) {
        let courseClasses=classesTemp.filter(clas => clas.courseID?._id === e.target.value)
          setclassesData(courseClasses)
      setfeeObj({
        classID: feeObj.classID,
        status: feeObj.status,
        courseID: e.target.value,
      })
    }else{
        setclassesData(classesTemp)
    }
  }
  const handleChange = e => {
    setfeeObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const exportData = studentEnrolledData?.map(cust => {
    const data = { ...cust }
    data.studentName = data.studentID?.studentName;
    
    data.stdID = data.studentID?.stdID;
    data.courseName = data.courseID?.courseName;
    data.className = data.classID?.className;

    data.amount="$"+data.Amount

    data.Date = moment(data.enrolledDate).format("ll")
    const obj = {
      "student name": data.studentName,
      "student ID": data.stdID,
      courseName: data.courseName,
      className: data.className,

      "amount":data.amount,
      enrolledStatus: data.enrolledStatus,
      Date: data.Date,
      
    }

    return obj
  })
  const data = {
    columns: [
      {
        label: "student Name",
        field: "studentName",
        sort: "asc",
        width: 150,
      },
      {
        label: "course",
        field: "courseName",
        sort: "asc",
        width: 270,
      },
      {
        label: "class Name",
        field: "className",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 100,
      },     
      {
        label: "enrolled Date",
        field: "Date",
        sort: "asc",
        width: 100,
      },     
      {
        label: "enrolled Status",
        field: "enrolledStatus",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: studentEnrolledData?.map(hos => {
      const data = { ...hos }
      data.studentName = data.studentID?.studentName+" -"+data.studentID?.stdID
      // data.studentName = data.studentID?.studentName;
      data.courseName = data.courseID?.courseName;
      data.className = data.classID?.className;
      data.amount="$"+data.Amount

      data.Date = moment(data.enrolledDate).format("ll")

      if (data.enrolledStatus == "active") {
        data.enrolledStatus = (
          <div className="badge bg-success  font-size-15 badge-pill mr-2">
            {data.enrolledStatus}
          </div>
        )
      } else {
        data.enrolledStatus = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.enrolledStatus}
          </div>
        )
      }
      
      

    
      return data
    }),
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs
              maintitle="Veltrix"
              title="class"
              breadcrumbItem="class"
            />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={HandleSubmitBtn}
                    >
                      <Row className=" mb-3">
                        <Col md={3}>
                          <div className="mb-3">
                            <FormGroup className="form-group">
                              <AvField
                                type="select"
                                name="courseID"
                                label="Select student"
                                value={feeObj.studentID}
                                onChange={e => handleChangesCourse(e)}
                                className="form-control"
                                //   errorMessage="please select class "
                                //   validate={{ required: { value: true } }}
                              >
                                {/* <option value="">Select Students</option> */}
                                <option value="">Select Course </option>
                                {courseData?.map(course => (
                                  <option value={course?._id}>
                                    {course.courseName}
                                  </option>
                                ))}
                              </AvField>
                            </FormGroup>
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mb-3">
                            <FormGroup className="form-group">
                              <AvField
                                type="select"
                                name="classID"
                                label="class"
                                value={feeObj.classID}
                                onChange={e => handleChange(e)}
                                className="form-control"
                                errorMessage="please select class "
                                validate={{ required: { value: true } }}
                              >
                                <option vale="">Select Class</option>
                                {classesData?.map(classes => (
                                  <option value={classes?._id}>
                                    {classes?.className}
                                  </option>
                                ))}
                              </AvField>
                            </FormGroup>
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mb-3">
                            <FormGroup className="form-group">
                              <AvField
                                type="select"
                                name="status"
                                label="Student status"
                                value={feeObj.status}
                                onChange={e => handleChange(e)}
                                className="form-control"
                                // errorMessage="please select fee status "
                                // validate={{ required: { value: true } }}
                              >    
                                <option value="active">Active</option>
                                <option value="graduated">graduated</option>
                                <option value="pending">pending</option>
                                <option value="waiting">waiting</option>
                                <option value="blocked">blocked</option>
                              </AvField>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>check out</Label>
                            <FormGroup className="form-group">
                              <Button
                                type="submit"
                                style={{
                                  background: "#0A928C",
                                  color: "white",
                                }}
                                className=" dropdown-toggle waves-effect waves-light "
                              >
                                <i className="ion ion-md-add me-2"></i> check
                                attendence
                              </Button>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                    <Row>
                      <div className="mb-3">
                        {/* <Label>Download List</Label> */}
                        <FormGroup className="form-group">
                          <CSVLink
                            filename={"TableContent.csv"}
                            data={exportData}
                            className="btn btn-success"
                          >
                            Download Excel
                          </CSVLink>
                        </FormGroup>
                      </div>
                    </Row>

                    <Row>
                      <MDBDataTable responsive striped bordered data={data} />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EnrollmentReport
